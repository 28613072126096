import $ from "jquery";
import { useEffect, useRef, useState } from "react";
import { useApi } from "../../../context/ApiContext";
import { useCommonPopup } from "../../../context/CommonPopupContext";

const PasswordConfirmPopup = (props) => {
  const { popupData, onCancel, onConfirm } = props;
  const api = useApi();
  const { alert } = useCommonPopup();

  const [password, setPassword] = useState();
  const [error, setError] = useState("");
  const passwordInputRef = useRef();

  useEffect(() => {
    setTimeout(() => {
      passwordInputRef.current?.focus();
    }, 200);

    return () => {};
  }, []);

  const onChangePassword = (e) => {
    const value = e.target.value;
    setPassword(value);
    setError("");
  };

  const onKeyEventPassword = (e) => {
    if (e.key === "Enter") {
      onClickConfirm();
    }
  };

  const onClickClose = () => {
    if (!!onCancel) {
      onCancel(popupData, false);
    }
  };

  const onClickConfirm = async () => {
    const result = await api.request("/admins/check_password/", {
      method: "POST",
      params: {
        password,
      },
    });

    if (result?.status !== 200) {
      await api.networkError(result, alert);
    } else {
      api.setAccessToken(result.data.accesstoken);
      if (!!onConfirm) {
        onConfirm(popupData, true);
      }
    }
  };

  return (
    // <div className="popup project_home_pop" >
    <div className="popup_wrap">
      {/* 공용 */}
      <div className="popup_inwrap common_pop medium">
        <h5 className="section_tit">
          비밀번호 <span className="close_btn" onClick={onClickClose}></span>
        </h5>
        <form
          action=""
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <div className="pop_content">
            <label htmlFor="password">
              본인 확인을 위해 비밀번호를 한 번 더 입력해 주세요.
              <br />{" "}
            </label>
            <div>
              <input
                name="password"
                type="password"
                ref={passwordInputRef}
                className={`full ${error.length > 0 ? "error" : ""}`}
                placeholder="비밀번호"
                autoComplete="new-password"
                value={password}
                onChange={onChangePassword}
                onKeyUp={onKeyEventPassword}
              />
            </div>
            <p className="tip">{error}</p>
          </div>
          <div className="pop_foot">
            <div className="but_wrap">
              <div>
                <button
                  type="button"
                  className="secondary gray large pop_close"
                  onClick={onClickClose}
                >
                  취소
                </button>
              </div>
              <div>
                <button
                  type="button"
                  className=" primary large "
                  onClick={onClickConfirm}
                >
                  확인
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    //  </div>
  );
};
export default PasswordConfirmPopup;
