/**
 * Tailwind UI를 위한 별도의 HTML 페이지를 로드하는 유틸리티 함수
 */

// Tailwind UI를 위한 iframe 생성
export const createTailwindIframe = (containerId) => {
  // 기존 iframe이 있으면 제거
  const existingIframe = document.getElementById("tailwind-iframe");
  if (existingIframe) {
    existingIframe.remove();
  }

  // 새 iframe 생성
  const iframe = document.createElement("iframe");
  iframe.id = "tailwind-iframe";
  iframe.src = "/tailwind.html";
  iframe.style.width = "100%";
  iframe.style.height = "100vh";
  iframe.style.border = "none";
  iframe.style.position = "fixed";
  iframe.style.top = "0";
  iframe.style.left = "0";
  iframe.style.zIndex = "1000";
  iframe.style.backgroundColor = "#f3f4f6";
  iframe.setAttribute("title", "설문조사 페이지");
  iframe.setAttribute("aria-label", "설문조사 페이지");
  iframe.setAttribute("allowfullscreen", "true");
  iframe.setAttribute("allow", "fullscreen");

  // iframe을 DOM에 추가
  const container = document.getElementById(containerId) || document.body;
  container.appendChild(iframe);

  // iframe 로드 완료 후 처리를 위한 Promise 반환
  return new Promise((resolve, reject) => {
    // 타임아웃 설정 (10초)
    const timeoutId = setTimeout(() => {
      reject(new Error("Iframe load timeout"));
    }, 10000);

    // tailwind-root 요소를 찾기 위한 재시도 함수
    const findRootElement = (retryCount = 0, maxRetries = 10) => {
      try {
        // iframe 문서에 접근
        const iframeDocument =
          iframe.contentDocument || iframe.contentWindow.document;
        if (!iframeDocument) {
          if (retryCount < maxRetries) {
            setTimeout(() => findRootElement(retryCount + 1, maxRetries), 300);
          } else {
            clearTimeout(timeoutId);
            reject(
              new Error("Cannot access iframe document after multiple attempts")
            );
          }
          return;
        }

        // tailwind-root 요소 찾기
        const rootElement = iframeDocument.getElementById("tailwind-root");
        if (!rootElement) {
          if (retryCount < maxRetries) {
            setTimeout(() => findRootElement(retryCount + 1, maxRetries), 300);
          } else {
            clearTimeout(timeoutId);
            reject(
              new Error(
                "tailwind-root element not found after multiple attempts"
              )
            );
          }
          return;
        }

        // 성공적으로 요소를 찾음
        clearTimeout(timeoutId);

        // 메인 페이지 스크롤 방지
        document.body.style.overflow = "hidden";

        resolve(iframe);
      } catch (error) {
        if (retryCount < maxRetries) {
          setTimeout(() => findRootElement(retryCount + 1, maxRetries), 300);
        } else {
          clearTimeout(timeoutId);
          reject(error);
        }
      }
    };

    iframe.onload = () => {
      // 로드 완료 후 tailwind-root 요소 찾기 시작
      findRootElement();
    };

    iframe.onerror = (error) => {
      clearTimeout(timeoutId);
      reject(error);
    };
  });
};

// Tailwind iframe 제거
export const removeTailwindIframe = () => {
  const iframe = document.getElementById("tailwind-iframe");
  if (iframe) {
    iframe.remove();

    // 메인 페이지 스크롤 복원
    document.body.style.overflow = "";
  }
};

// iframe 내부에 React 컴포넌트 렌더링을 위한 준비
export const setupTailwindIframeForReact = async (iframe) => {
  try {
    const iframeDocument =
      iframe.contentDocument || iframe.contentWindow.document;
    const rootElement = iframeDocument.getElementById("tailwind-root");

    if (!rootElement) {
      throw new Error("tailwind-root element not found in iframe");
    }

    return rootElement;
  } catch (error) {
    throw error;
  }
};

// iframe 내부에 스크립트 추가
export const addScriptToIframe = (iframe, src) => {
  try {
    const iframeDocument =
      iframe.contentDocument || iframe.contentWindow.document;
    const script = iframeDocument.createElement("script");
    script.src = src;
    iframeDocument.head.appendChild(script);

    return new Promise((resolve, reject) => {
      script.onload = () => {
        resolve();
      };
      script.onerror = (error) => {
        reject(error);
      };
    });
  } catch (error) {
    return Promise.reject(error);
  }
};

// iframe 내부에 스타일 추가
export const addStyleToIframe = (iframe, css) => {
  try {
    const iframeDocument =
      iframe.contentDocument || iframe.contentWindow.document;
    const style = iframeDocument.createElement("style");
    style.textContent = css;
    iframeDocument.head.appendChild(style);
  } catch (error) {
    console.error("Error adding style to iframe:", error);
  }
};

// iframe과 부모 페이지 간 통신 설정
export const setupIframeCommunication = (iframe, handlers = {}) => {
  // 부모 페이지에서 iframe으로 메시지 전송
  const sendMessageToIframe = (message) => {
    iframe.contentWindow.postMessage(message, window.location.origin);
  };

  // iframe에서 메시지 수신
  const messageListener = (event) => {
    if (event.origin !== window.location.origin) {
      return;
    }

    const { type, data } = event.data;
    if (handlers[type]) {
      handlers[type](data);
    }
  };

  window.addEventListener("message", messageListener);

  return {
    sendMessageToIframe,
    cleanup: () => {
      window.removeEventListener("message", messageListener);
    },
  };
};
