import { useState } from "react";
import {
  SURVEY_STATUS_CLOSED,
  SURVEY_STATUS_WRITING,
} from "../../../common/codeHelper";

const QuestionIndex = (props) => {
  const {
    survey,
    question,
    indexRefs,
    onDragOver,
    onDragEnd,
    onDrop,
    dragging,
    setDraggable,
    onClickIndex,
    onClickDeleteQuestion,
    showNumbers,
  } = props;

  const [showTooltip, setShowTooltip] = useState(false);

  const onMouseOver = (e) => {
    setDraggable(true);
    setShowTooltip(true);
  };

  const onMouseLeave = (e) => {
    setDraggable(false);
    setShowTooltip(false);
  };

  const onDragStart = (e) => {
    setShowTooltip(false);
    props.onDragStart(e);
  };

  return (
    <div
      onClick={() => {
        onClickIndex(question?.listIndex);
      }}
    >
      <li
        data-position={question?.listIndex}
        onDragOver={onDragOver}
        onDragStart={onDragStart}
        onDragEnd={onDragEnd}
        onDrop={onDrop}
        draggable
        ref={indexRefs[`${question?.listIndex}`]}
        className={
          !!survey.selectedItem?.idquestion &&
          survey.selectedItem?.idquestion === question.idquestion
            ? "active"
            : ""
        }
      >
        {showNumbers && `${question.sectionIndex}-${question.orderno}. `}
        {question.content}
        {survey.hasError(`question-${question.idquestion}`) && (
          <i className="icon-warning c_danger"></i>
        )}
        {survey.surveyData?.status === SURVEY_STATUS_WRITING && (
          <div>
            <i
              className={`icon-delete survey-del-btn ${
                question.orderno === 1 ? "c_disabled" : ""
              }`}
              onClick={() => {
                onClickDeleteQuestion(question);
              }}
            />
            <i
              className="icon-menu"
              onMouseOver={onMouseOver}
              onMouseLeave={onMouseLeave}
            ></i>
          </div>
        )}
      </li>
      {showTooltip && dragging === false && (
        <span className="tooltext">
          문항의 순서를 변경하면서, <br />
          답변 이동 문항 번호는 그대로 유지해도 문제가 없는지 확인해주세요.
        </span>
      )}
    </div>
  );
};

export default QuestionIndex;
