import { useEffect, useState } from "react";
import {
  SURVEY_STATUS_CLOSED,
  SURVEY_STATUS_WRITING,
} from "../../../common/codeHelper";

const SectionIndex = (props) => {
  const {
    survey,
    section,
    indexRefs,
    onDragOver,
    onDragEnd,
    onDrop,
    dragging,
    setDraggable,
    onClickIndex,
    onClickDeleteSection,
    showNumbers,
  } = props;

  const [showTooltip, setShowTooltip] = useState(false);

  const onMouseOver = (e) => {
    setDraggable(true);
    setShowTooltip(true);
  };

  const onMouseLeave = (e) => {
    setDraggable(false);
    setShowTooltip(false);
  };

  const onDragStart = (e) => {
    setShowTooltip(false);
    props.onDragStart(e);
  };

  return (
    <>
      <div
        onClick={() => {
          onClickIndex(section?.listIndex);
        }}
      >
        <li
          ref={indexRefs[`${section?.listIndex}`]}
          data-position={section?.listIndex}
          onDragOver={onDragOver}
          onDragStart={onDragStart}
          onDragEnd={onDragEnd}
          onDrop={onDrop}
          draggable
          className={
            !!survey.selectedItem?.idsection &&
            survey.selectedItem?.idsection === section.idsection
              ? "active"
              : ""
          }
        >
          <h5>
            {showNumbers && `${section.orderno}. `}
            {section.name}
          </h5>
          {survey.hasError(`section-${section.idsection}`) && (
            <i className="icon-warning c_danger"></i>
          )}
          {survey.surveyData?.status === SURVEY_STATUS_WRITING && (
            <div>
              <i
                className={`icon-delete survey-del-btn ${
                  section.orderno === 1 ? "c_disabled" : ""
                }`}
                onClick={() => {
                  onClickDeleteSection(section);
                }}
              />
              <i
                className="icon-menu"
                onMouseOver={onMouseOver}
                onMouseLeave={onMouseLeave}
              />
            </div>
          )}
        </li>
        {showTooltip && dragging === false && (
          <span className="tooltext">
            문항의 순서를 변경하면서, <br />
            답변 이동 문항 번호는 그대로 유지해도 문제가 없는지 확인해주세요.
          </span>
        )}
      </div>
    </>
  );
};
export default SectionIndex;
