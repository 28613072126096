import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useApi } from "../../context/ApiContext";
import queryString from "query-string";
import AnswerSection from "./AnswerSection";
import AnswerQuestion from "./AnswerQuestion";
import AnswerQuestionTailwind from "./AnswerQuestionTailwind";
import AnswerHeader from "./AnswerHeader";
import setScreenHeight from "../../common/javascriptHelper";
import {
  createTailwindIframe,
  removeTailwindIframe,
  setupIframeCommunication,
} from "../../utils/tailwindUtils";
import * as ReactDOM from "react-dom/client";

const AnswerContent = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const api = useApi();
  const [accessToken, setAccessToken] = useState();

  const oldData = useRef();
  const [currentData, setCurrentData] = useState();
  const [error, setError] = useState();
  const [tailwindIframe, setTailwindIframe] = useState(null);
  const tailwindRootRef = useRef(null);
  const reactRootRef = useRef(null);
  const iframeCommunication = useRef(null);
  const isRendering = useRef(false);
  const lastRenderedData = useRef(null);

  // 상태 변수 정의
  const isError = error === true;
  const isCompleted = !!currentData && !!currentData.completed;
  const isSection =
    !!currentData && !currentData.questions && !currentData.question;
  const shouldUseTailwindUI =
    !isError &&
    !isCompleted &&
    !isSection &&
    !!currentData?.questions &&
    currentData.survey?.expand_sections === true;

  useEffect((_) => {
    window.addEventListener("resize", setScreenHeight);
    setTimeout(() => {
      setScreenHeight();
    }, 200);

    return () => {
      window.removeEventListener("resize", setScreenHeight);
      // iframe 제거
      removeTailwindIframe();
      // iframe 통신 정리
      if (iframeCommunication.current) {
        iframeCommunication.current.cleanup();
      }

      // 안전하게 React root 정리를 위한 setTimeout 추가
      if (reactRootRef.current) {
        const root = reactRootRef.current;
        // 안전하게 현재 렌더링 사이클이 끝난 후 unmount 실행
        setTimeout(() => {
          try {
            root.unmount();
          } catch (e) {
            console.error("React root unmount error:", e);
          }
        }, 0);
        reactRootRef.current = null;
      }
    };
  }, []);

  useEffect((_) => {}, [props]);

  useEffect(
    (_) => {
      const query = queryString.parse(location.search);
      if (!!query?.accesstoken) {
        setAccessToken(query.accesstoken);
        showQuestion(query.accesstoken, location.state?.moveaction ?? "START");
      } else {
        navigate("/answer");
      }
    },
    [location]
  );

  // Tailwind UI를 사용하는 경우 iframe 설정
  useEffect(() => {
    if (shouldUseTailwindUI && !tailwindIframe) {
      // iframe 생성 및 설정
      const setupTailwindUI = async () => {
        try {
          const iframe = await createTailwindIframe("root");
          setTailwindIframe(iframe);

          // iframe 내부의 root 요소 참조 저장
          const iframeDocument =
            iframe.contentDocument || iframe.contentWindow.document;
          const rootElement = iframeDocument.getElementById("tailwind-root");

          if (!rootElement) {
            console.error("Tailwind root element not found in iframe");
            return;
          }

          tailwindRootRef.current = rootElement;

          // iframe과 부모 페이지 간 통신 설정
          iframeCommunication.current = setupIframeCommunication(iframe, {
            PREV_CLICK: () => onClickPrev(),
            NEXT_CLICK: (answer) => onClickNext(answer),
            IFRAME_READY: () => {
              if (!isRendering.current) {
                renderTailwindUI();
              }
            },
            IFRAME_LOADED: () => {
              if (!isRendering.current) {
                renderTailwindUI();
              }
            },
          });

          // Tailwind UI 렌더링
          renderTailwindUI();
        } catch (error) {
          console.error("Tailwind UI 설정 오류:", error);
        }
      };

      setupTailwindUI();
    } else if (!shouldUseTailwindUI && tailwindIframe) {
      // Tailwind UI가 더 이상 필요하지 않은 경우 iframe 제거
      removeTailwindIframe();
      setTailwindIframe(null);
      tailwindRootRef.current = null;
      lastRenderedData.current = null;

      // React root 정리 - 안전하게 비동기 처리
      if (reactRootRef.current) {
        const root = reactRootRef.current;
        // 현재 렌더링 사이클 이후에 unmount 실행
        setTimeout(() => {
          try {
            root.unmount();
          } catch (e) {
            console.error("React root unmount error:", e);
          }
        }, 0);
        reactRootRef.current = null;
      }

      // iframe 통신 정리
      if (iframeCommunication.current) {
        iframeCommunication.current.cleanup();
        iframeCommunication.current = null;
      }
    }
  }, [shouldUseTailwindUI, tailwindIframe]);

  // Tailwind UI 렌더링 함수
  const renderTailwindUI = () => {
    if (!tailwindRootRef.current || !currentData) {
      return;
    }

    // 이미 렌더링 중이면 중복 렌더링 방지
    if (isRendering.current) {
      return;
    }

    // 강제 렌더링 여부 판단 (answerdisabled 속성만 확인)
    let needRendering = true;

    // 이전에 렌더링한 데이터가 있으면 answerdisabled 상태 비교
    if (lastRenderedData.current) {
      const oldQuestions = lastRenderedData.current.questions || [];
      const newQuestions = currentData.questions || [];

      // 질문 수가 다르면 무조건 렌더링
      if (oldQuestions.length === newQuestions.length) {
        // 모든 질문의 answerdisabled 상태가 동일한지 확인
        const hasChangedDisabledState = newQuestions.some(
          (newQ, index) =>
            newQ.answerdisabled !== oldQuestions[index].answerdisabled
        );

        if (!hasChangedDisabledState) {
          // 변경된 상태가 없으면 렌더링 스킵
          needRendering = false;
        }
      }
    }

    // 강제 렌더링 여부 또는 DIRECT 모드인 경우 항상 렌더링
    if (location.state?.moveaction === "DIRECT") {
      needRendering = true;
    }

    // 렌더링 필요 없으면 스킵
    if (!needRendering) {
      return;
    }

    isRendering.current = true;

    try {
      // React 18에서는 unmount 없이 새 컴포넌트를 렌더링할 수 있음
      if (!reactRootRef.current && tailwindRootRef.current) {
        reactRootRef.current = ReactDOM.createRoot(tailwindRootRef.current);
      }

      // 컴포넌트 렌더링 (기존 root가 있다면 자동으로 업데이트됨)
      if (reactRootRef.current) {
        reactRootRef.current.render(
          <AnswerQuestionTailwind
            currentData={currentData}
            accessToken={accessToken}
            onPrev={onClickPrev}
            onNext={onClickNext}
            inIframe={true}
            api={api}
            refreshQuestions={refreshQuestions}
          />
        );
      }

      // 렌더링된 데이터 저장 (깊은 복사를 통해 저장)
      lastRenderedData.current = JSON.parse(JSON.stringify(currentData));
    } catch (error) {
      console.error("Tailwind UI 렌더링 오류:", error);
    } finally {
      // 렌더링 완료
      isRendering.current = false;
    }
  };

  useEffect(
    (_) => {
      oldData.current = {
        ...currentData,
      };
      setError(false);
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });

      // currentData가 변경되었을 때 iframe 강제 렌더링
      if (
        tailwindRootRef.current &&
        shouldUseTailwindUI &&
        !isRendering.current
      ) {
        renderTailwindUI();
      }
    },
    [currentData, shouldUseTailwindUI]
  );

  const showQuestion = async (
    accesstoken,
    moveaction,
    questionOrder = null
  ) => {
    // 첫 번째 호출(moveaction이 "START"인 경우)에는 항상 show_question 사용
    let endpoint = "/surveys/show_question/";
    if (
      moveaction !== "START" &&
      currentData?.survey?.expand_sections === true
    ) {
      endpoint = "/surveys/show_questions/";
    }

    const result = await api.request(endpoint, {
      method: "post",
      params: {
        accesstoken: accesstoken,
        moveaction: moveaction,
        currentsectionorderno: location.state?.currentsectionorderno ?? 0,
        currentquestionorderno:
          questionOrder ?? location.state?.currentquestionorderno ?? 0,
        answercontent: location.state?.answer,
      },
    });

    if (result?.status !== 200) {
      setError(true);
    } else {
      setCurrentData(result.data);
    }

    return result;
  };

  // 질문 목록 새로고침 함수 (DIRECT 모드 지원)
  const refreshQuestions = () => {
    showQuestion(accessToken, "DIRECT", 1);
  };

  const onClickPrev = () => {
    navigate(`${location.search}`, {
      state: {
        moveaction: "PREV",
        currentsectionorderno: currentData?.section?.orderno,
        currentquestionorderno:
          currentData?.currentquestionorderno ||
          (currentData?.questions && currentData?.questions[0]?.orderno) ||
          (currentData?.question && currentData?.question?.orderno),
        answer: null,
      },
      replace: true,
    });
  };

  const onClickNext = (answer = undefined) => {
    navigate(`${location.search}`, {
      state: {
        moveaction: "NEXT",
        currentsectionorderno: currentData?.section?.orderno,
        currentquestionorderno:
          currentData?.currentquestionorderno ||
          (currentData?.questions && currentData?.questions[0]?.orderno) ||
          (currentData?.question && currentData?.question?.orderno),
        answer,
      },
      replace: true,
    });
  };

  const onClickRestart = async () => {
    const result = await api.request("/answers/restart/", {
      method: "post",
      params: {
        accesstoken: accessToken,
      },
    });

    if (result?.status !== 200) {
      api.networkError(result, alert);
    } else {
      if (!!result.data.accesstoken) {
        navigate(`/answer?accesstoken=${result.data.accesstoken}`, {
          replace: true,
        });
      }
    }
  };

  const AnswerComplete = () => {
    const onClickClose = () => {
      window.close();
    };

    return (
      <div className="inwrap start">
        <div className="questionnair_body">
          <div></div>
          <h3>
            👍👏🥰<span className="red_c">❤</span> <br />
            설문을 완료했습니다.
          </h3>
          <h4 className="">참여해주셔서 진심으로 감사드립니다.</h4>

          <h3>Survey completed successfully.</h3>
          <h4 className="">Thank you for your participation.</h4>
        </div>
        {!!currentData?.survey?.multipleresponse && (
          <div className="but_wrap">
            <button
              type="button"
              className="primary large full"
              onClick={onClickRestart}
            >
              다시하기 (Restart)
            </button>
          </div>
        )}
      </div>
    );
  };

  const AnswerError = () => {
    return (
      <div className="inwrap start">
        <div className="questionnair_body">
          <div></div>
          <h3>
            😵 <br />
            설문지를 표시할 수 없습니다.
          </h3>
          <h4>이전 화면으로 돌아가 다시 시도해보세요. </h4>
          <h4>
            계속해서 문제가 발생한다면, contact@atflow.kr로 이메일을 보내주시면
            신속하게 도움을 드리겠습니다.
          </h4>
          <h4>불편을 끼쳐드려 죄송합니다.</h4>

          <h3>Unable to load the survey.</h3>
          <h4>Please go back to the previous screen and try again. </h4>
          <h4>
            If the issue persists, please email us at contact@atflow.kr and we
            will assist you promptly.
          </h4>
          <h4>We apologize for the inconvenience.</h4>
        </div>
        <div className="but_wrap">
          <button
            type="button"
            className="secondary gray large full"
            onClick={onClickPrev}
          >
            이전 (Go Back)
          </button>
        </div>
      </div>
    );
  };

  // Tailwind UI를 사용하는 경우 빈 div 반환 (iframe에서 렌더링)
  if (shouldUseTailwindUI) {
    return <div id="tailwind-container" aria-hidden="true"></div>;
  }

  // 기존 UI 사용
  return (
    <div className="body questionnair">
      <AnswerHeader currentData={currentData} />
      <div className="container">
        {isError ? (
          <AnswerError />
        ) : isCompleted ? (
          <AnswerComplete />
        ) : isSection ? (
          <AnswerSection
            currentData={currentData}
            accessToken={accessToken}
            onPrev={onClickPrev}
            onNext={onClickNext}
          />
        ) : !!currentData && !!currentData.question ? (
          <AnswerQuestion
            currentData={currentData}
            accessToken={accessToken}
            onPrev={onClickPrev}
            onNext={onClickNext}
          />
        ) : null}
      </div>
    </div>
  );
};

export default AnswerContent;
