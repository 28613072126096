import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { useApi } from "../../context/ApiContext";
import { validateEamil, validatePassword } from "../../common/stringHelper";
import { useCommonPopup } from "../../context/CommonPopupContext";
import { toast } from "react-hot-toast";

const Signup = () => {
  let navigate = useNavigate();
  let location = useLocation();

  const api = useApi();
  const { alert } = useCommonPopup();

  const [authStatus, setAuthStatus] = useState("none");
  const [inputs, setInputs] = useState({
    email: "",
    password1: "",
    password2: "",
  });

  const [errors, setErrors] = useState({
    emailError: "",
    password1Error: "",
    password2Error: "",
    authError: "",
    agreementError: "",
  });

  const { email, password1, password2 } = inputs;
  const {
    emailError,
    password1Error,
    password2Error,
    authError,
    agreementError,
  } = errors;

  const [agreementList, setAgreementList] = useState([]);
  const [agreeAll, setAgreeAll] = useState(false);
  // const [agreeAge, setAgreeAge] = useState(false)
  const inputEmail = useRef();
  const inputPassword1 = useRef();
  const inputPassword2 = useRef();
  const appauthkey = useRef();
  const [showError, setShowError] = useState(false);

  useEffect((_) => {
    const query = queryString.parse(location.search);
    if (!!query?.email && !!query?.appauthkey) {
      appauthkey.current = query.appauthkey;
      setInputs({
        ...inputs,
        email: query.email,
      });
      setAuthStatus("auth");
    }
    getAgreementList();
  }, []);

  useEffect(
    (_) => {
      setAgreeAll(
        agreementList.filter((element) => element.agree !== 1)?.length === 0
      );
      if (showError) {
        if (
          agreementList.filter(
            (element) => element.mandatory === 1 && element.agree === 0
          )?.length > 0
        ) {
          setError(
            "agreement",
            "회원가입을 진행하시려면 약관에 동의하셔야 합니다."
          );
        } else {
          setError("agreement", "");
        }
      }
    },
    [agreementList]
  );

  const getAgreementList = async () => {
    const result = await api.request("/agreements/");

    if (result?.status !== 200) {
      api.networkError(result, alert);
    } else {
      setAgreementList(
        result.data.map((element) => ({
          ...element,
          agree: 0,
        }))
      );
    }
  };

  const setError = (name, error) => {
    const temp = {
      ...errors,
      [`${name}Error`]: error,
    };
    setErrors({
      ...errors,
      [`${name}Error`]: error,
    });
  };

  const checkEamil = (value) => {
    if (value.length == 0) {
      setError("email", "이메일을 입력하세요.");
    } else if (!validateEamil(value)) {
      inputEmail.current.focus();
      setError(
        "email",
        "이메일 형식에 맞지 않습니다. @를 포함하여 정확하게 입력해주세요."
      );
    } else {
      setError("email", "");
    }
  };

  const checkPassword1 = (value) => {
    var password1Error = "";
    var password2Error = "";
    if (value.length == 0) {
      password1Error = "";
    } else if (value.length < 8) {
      password1Error = "비밀번호는 8자리 이상이어야 합니다.";
    } else if (!validatePassword(email, value)) {
      password1Error =
        "비밀번호에 이메일 주소의 일부, 연속된 숫자 혹은 동일한 문자 3자리 이상을 사용하지 마세요.";
    } else {
      password1Error = "";
    }
    if (password2.length > 0 && value.length > 0 && value != password2) {
      password2Error = "비밀번호가 서로 일치하지 않습니다.";
    } else {
      password2Error = "";
    }

    setErrors({
      ...errors,
      [`password1Error`]: password1Error,
      [`password2Error`]: password2Error,
    });
  };

  const checkPassword2 = (value) => {
    if (password1.length == 0) {
      setError("password1", "비밀번호를 입력하세요.");
    }
    if (value.length > 0 && password1 != value) {
      setError("password2", "비밀번호가 서로 일치하지 않습니다.");
    } else {
      setError("password2", "");
    }
  };

  const onChangeInput = (e) => {
    var { value, name } = e.target;
    value = value.replace(/ /g, "");
    setInputs({
      ...inputs,
      [name]: value,
    });

    if (name == "email") {
      checkEamil(value);
    } else if (name == "password1") {
      checkPassword1(value);
    } else if (name == "password2") {
      checkPassword2(value);
    }
  };

  const onKeyEvent = (e) => {
    var { key, name } = e.target;
    if (e.key === "Enter") {
      if (name == "password1") {
        inputPassword2.current.focus();
      } else if (name == "password2") {
        onClickNext();
      }
    }
  };

  const onClickSendAuthEmail = async () => {
    if (authStatus != "none") return;

    if (email.length == 0) {
      inputEmail.current.focus();
      setError("email", "이메일을 입력하세요.");
      return;
    }
    if (!validateEamil(email)) {
      inputEmail.current.focus();
      setError(
        "email",
        "이메일 형식에 맞지 않습니다. @를 포함하여 정확하게 입력해주세요."
      );
      return;
    }

    setAuthStatus("send");

    const result = await api.request("/auth/send_auth_email/", {
      method: "POST",
      params: { email },
    });

    if (result?.status !== 200) {
      if (result?.data?.detail?.length > 0) {
        setError("email", result.data.detail);
      } else {
        setError("email", "error");
      }
      setAuthStatus("none");
    } else {
      appauthkey.current = result.data.appauthkey;
      setError("email", "");
      setAuthStatus("sent");
    }
  };

  const onClickResendAuthEmail = async () => {
    const result = await api.request("/auth/send_auth_email/", {
      method: "POST",
      params: { email },
    });

    if (result?.status !== 200) {
    } else {
      appauthkey.current = result.data.appauthkey;
      toast.success("인증메일이 재전송되었습니다.");
    }
  };

  const onClickCheckAuthEmail = async (_) => {
    if (authStatus != "sent") return;

    const result = await api.request("/auth/check_signin_email_auth/", {
      method: "POST",
      params: {
        email,
        appauthkey: appauthkey.current,
      },
    });

    if (result?.status !== 200) {
      if (result?.data?.detail?.length > 0) {
        alert(`${result.data.detail}`, { title: "이메일 인증 실패" });
      } else {
        alert(`이메일 인증 실패`);
      }
    } else {
      inputPassword1.current.focus();
      setAuthStatus("auth");
      setError("auth", "");
    }
  };

  const onCheckAgreeAll = (value) => {
    setAgreeAll(value);
    // setAgreeAge(value)
    setAgreementList(
      agreementList.map((element) => ({
        ...element,
        agree: value ? 1 : 0,
      }))
    );
  };

  // const onCheckAgreementAge = (value) => {

  //   setAgreeAge(value)
  // }

  const onCheckAgreement = (agreement, value) => {
    setAgreementList(
      agreementList.map((element) =>
        agreement.idagreement === element.idagreement
          ? { ...element, agree: value ? 1 : 0 }
          : element
      )
    );
  };

  const onClickAgreement = (e, agreement) => {
    if (agreement.hascontent !== 1) return;
    e.preventDefault();

    window.open(`/agreement/${agreement.idagreement}`);
  };

  const onClickCancel = async (_) => {
    navigate("/signin", { replace: true });
  };

  const checkValidation = (_) => {
    if (authStatus != "auth") {
      inputEmail.current.focus();
      setError(
        "auth",
        "다음 단계를 진행하기 위해 이메일 인증이 필요합니다.\n우선 이메일 인증을 진행해주세요."
      );
      return false;
    }
    if (password1.length == 0) {
      inputPassword1.current.focus();
      setError("password1", "비밀번호를 입력하세요.");
      return false;
    }
    if (password1.length < 8) {
      inputPassword1.current.focus();
      setError("password1", "비밀번호는 8자리 이상이어야 합니다.");
      return false;
    }
    if (!validatePassword(email, password1)) {
      inputPassword1.current.focus();
      setError(
        "password1",
        "비밀번호에 이메일 주소의 일부, 연속된 숫자 혹은 동일한 문자 3자리 이상을 사용하지 마세요."
      );
      return false;
    }
    if (password2.length == 0) {
      inputPassword2.current.focus();
      setError("password2", "비밀번호를 다시 한번 입력해 주세요.");
      return false;
    }
    if (password1 != password2) {
      inputPassword2.current.focus();
      setError("password2", "비밀번호가 서로 일치하지 않습니다.");
      return false;
    }
    if (
      agreementList.filter(
        (element) => element.mandatory === 1 && element.agree === 0
      )?.length > 0
    ) {
      setError(
        "agreement",
        "회원가입을 진행하시려면 약관에 동의하셔야 합니다."
      );
      return false;
    }
    return true;
  };

  const onClickNext = async (_) => {
    setShowError(true);
    if (!checkValidation()) return;

    navigate("/signup/2", {
      state: {
        email: email,
        password: password1,
        appauthkey: appauthkey.current,
        agreements: agreementList.map((element) => ({
          agreement: element.idagreement,
          agree: element.agree,
        })),
      },
      replace: true,
    });
  };

  return (
    <div id="join_step1" className="body">
      <div className="login join">
        <div className="inwrap">
          <div>
            <ul>
              <li>
                <i className="icon-logo"></i>
                <h4>연구를 쉽고 빠르게!</h4>
                <p>손쉽게 설문 또는 문진을 진행할 수 있습니다.</p>
                <ul>
                  <li>40여개의 표준화된 척도를 출처와 함께 제공</li>
                  <li>
                    한눈에 볼 수 있는 요약통계와 통계 프로그램에 적합한 data set
                    제공
                  </li>
                  <li>조건 지정 후 맞춤 패널 제공</li>
                </ul>
                <img src="../../../assets/images/signin_1.png" alt="" />
              </li>
              <li>
                <form
                  action=""
                  autoComplete="new-password"
                  onSubmit={(e) => {
                    e.preventDefault();
                  }}
                >
                  <h4>회원가입</h4>
                  <dl>
                    <dt>이메일 * </dt>
                    <dd>
                      <ul>
                        <li className="first_child">
                          <input
                            name="email"
                            type="text"
                            ref={inputEmail}
                            className={`${
                              authStatus != "none" ? "disabled" : ""
                            } ${emailError.length > 0 ? "error" : ""}`}
                            autoComplete="new-password"
                            placeholder="이메일"
                            value={email}
                            disabled={authStatus == "auth"}
                            onChange={onChangeInput}
                          />
                        </li>
                        <li>
                          <button
                            type="button"
                            className={
                              authStatus == "auth"
                                ? "disabled"
                                : authStatus == "sent"
                                ? "primary"
                                : "secondary"
                            }
                            disabled={authStatus == "auth"}
                            onClick={
                              authStatus == "sent" || authStatus == "send"
                                ? onClickCheckAuthEmail
                                : onClickSendAuthEmail
                            }
                          >
                            {authStatus == "auth"
                              ? "인증완료"
                              : authStatus == "sent"
                              ? "인증확인"
                              : "인증하기"}
                          </button>
                        </li>
                      </ul>
                      <div
                        style={authStatus != "sent" ? { display: "none" } : {}}
                      >
                        <p>
                          입력하신 계정으로 인증 메일을 보내드렸습니다. <br />
                          이메일을 열어 인증 후 인증확인 버튼을 눌러주세요.
                        </p>
                        <p>
                          인증을 못 받으셨다면?{" "}
                          <a className="" onClick={onClickResendAuthEmail}>
                            다시보내기
                          </a>
                        </p>
                      </div>
                      <p className="tip">{emailError}</p>
                    </dd>
                    <dt>비밀번호 *</dt>
                    <dd>
                      <input
                        name="password1"
                        type="password"
                        ref={inputPassword1}
                        className={password1Error.length > 0 ? "error" : ""}
                        autoComplete="new-password"
                        placeholder="비밀번호"
                        value={password1}
                        onChange={onChangeInput}
                        onKeyUp={onKeyEvent}
                      />
                      <p className="tip">{password1Error}</p>
                    </dd>
                    <dt>비밀번호 확인 *</dt>
                    <dd>
                      <input
                        name="password2"
                        type="password"
                        ref={inputPassword2}
                        className={password2Error.length > 0 ? "error" : ""}
                        autoComplete="new-password"
                        placeholder="비밀번호 확인"
                        value={password2}
                        onChange={onChangeInput}
                        onKeyUp={onKeyEvent}
                      />
                      <p className="tip">{password2Error}</p>
                    </dd>
                    <dt className="checkbox">
                      <label>
                        <input
                          type="checkbox"
                          name="checkbox"
                          checked={agreeAll ?? false}
                          onChange={(e) => onCheckAgreeAll(e.target.checked)}
                        />
                        <span>서비스 이용 약관에 모두 동의합니다.</span>
                      </label>
                      <label>
                        <p className="font_12">
                          전체 동의는 필수 및 선택정보에 대한 동의도 포함되어
                          있으며, 개별적으로도 동의를 선택하실 수 있습니다.
                          선택항목에 대한 동의를 거부하셔도 서비스 이용이
                          가능합니다.
                        </p>
                      </label>
                      <div>
                        {/* <label>
                          <input type="checkbox" name="checkbox" checked={agreeAge?? false} onChange={(e) => onCheckAgreementAge(e.target.checked)}/>
                          <span>(필수) 만 14세 확인</span>
                        </label> */}
                        {agreementList?.map((agreement, index) => (
                          <label key={`agreement-${index}`}>
                            <input
                              type="checkbox"
                              name="checkbox"
                              checked={agreement.agree === 1}
                              onChange={(e) =>
                                onCheckAgreement(agreement, e.target.checked)
                              }
                            />
                            <span>
                              {agreement.mandatory === 1 ? "(필수)" : "(선택)"}
                              {agreement.hascontent === 1 ? (
                                <a
                                  onClick={(e) =>
                                    onClickAgreement(e, agreement)
                                  }
                                >
                                  {agreement.title}
                                </a>
                              ) : (
                                <span>{agreement.title}</span>
                              )}
                            </span>
                          </label>
                        ))}
                      </div>
                    </dt>
                  </dl>
                  <div className="but_wrap">
                    <div>
                      <button
                        type="button"
                        className="secondary large"
                        onClick={onClickCancel}
                      >
                        취소
                      </button>
                    </div>
                    <div>
                      <button
                        type="button"
                        className="primary large"
                        onClick={onClickNext}
                      >
                        다음
                      </button>
                    </div>
                  </div>
                  <p className="tip">
                    {authError?.length > 0 ? authError : agreementError}
                  </p>
                </form>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signup;
