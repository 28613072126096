import { useQuestionLogic } from "../../utils/questionUtils";
import { useEffect, useRef, useState, useCallback } from "react";
import {
  SURVEY_QUESTION_TYPE_YESORNO,
  SURVEY_QUESTION_TYPE_MULTIPLECHOICE,
  SURVEY_QUESTION_TYPE_SCORE,
  SURVEY_QUESTION_TYPE_ESSAY,
  SURVEY_QUESTION_TYPE_DATE,
  SURVEY_QUESTION_TYPE_SHORTANSWER,
  SURVEY_QUESTION_TYPE_YEAR,
} from "../../common/codeHelper";
import { questionImageUrl } from "../../common/imageHelper";
import { validateRealNumber } from "../../common/stringHelper";
import React from "react";

const AnswerQuestionTailwind = (props) => {
  const {
    updateAnswer,
    updateReason,
    isSelected,
    onClickPrev,
    onClickNext,
    isExpandSections,
    getAnswer,
    getReasons,
    checkValidation,
  } = useQuestionLogic(props);

  const [currentData, setCurrentData] = useState();
  // 컴포넌트가 마운트되었는지 추적
  const isMounted = useRef(false);
  // 이전 props를 저장
  const prevPropsRef = useRef();

  const imageRef = useRef();
  const [imageHeight, setImageHeight] = useState(100);
  const [imageKey, setImageKey] = useState(0);

  // 각 질문의 응답 상태를 추적하는 state (서버 응답 기준)
  const [validQuestions, setValidQuestions] = useState({});
  // 질문 DOM 요소를 저장할 ref 객체
  const questionRefsRef = useRef({});

  const updateImageHeight = useCallback(() => {
    if (!imageRef.current) return;
    const newHeight = (5 / 4) * imageRef.current.offsetWidth;
    setImageHeight(newHeight);
    setImageKey((prevKey) => prevKey + 1);
  }, []);

  // 초기 마운트 시 각 질문의 유효성 초기화
  useEffect(() => {
    if (currentData && currentData.questions) {
      const initialValidity = {};

      currentData.questions.forEach((question) => {
        // 이미 답변이 있는 경우 유효하다고 간주
        const answer = getAnswer(question);
        const reasons = getReasons(question);
        const isValid = checkValidation(question, answer, reasons);

        initialValidity[question.idquestion] = isValid;
      });

      setValidQuestions(initialValidity);
    }
  }, [currentData, getAnswer, getReasons, checkValidation]);

  useEffect(() => {
    isMounted.current = true;
    updateImageHeight();
    window.addEventListener("resize", updateImageHeight);

    return () => {
      isMounted.current = false;
      window.removeEventListener("resize", updateImageHeight);
    };
  }, [updateImageHeight]);

  // props.currentData가 변경될 때만 내부 상태 업데이트
  useEffect(() => {
    // 마운트되지 않았거나 props.currentData가 없으면 무시
    if (!isMounted.current || !props.currentData) return;

    // 이전 props와 현재 props가 같으면 무시
    if (prevPropsRef.current === props.currentData) return;

    try {
      // 상태 업데이트 - 직접 currentData를 설정
      setCurrentData(props.currentData);
      setImageKey((prevKey) => prevKey + 1);

      // 현재 props 저장
      prevPropsRef.current = props.currentData;
    } catch (error) {
      console.error("데이터 처리 오류:", error);
    }
  }, [props.currentData]);

  // iframe 내에서 작동 중일 때 부모 페이지에 준비 완료 알림 (한 번만 실행)
  useEffect(() => {
    if (props.inIframe) {
      // iframe이 준비되었음을 부모 페이지에 알림 (한 번만)
      window.parent.postMessage(
        { type: "IFRAME_READY" },
        window.location.origin
      );
    }
  }, [props.inIframe]);

  // 특정 질문으로 스크롤하고 포커스하는 공통 함수
  const scrollToQuestionAndFocus = useCallback((question) => {
    if (!question || !questionRefsRef.current) return;

    const questionEl = questionRefsRef.current[question.idquestion];
    if (!questionEl) return;

    // 부드러운 스크롤로 이동
    questionEl.scrollIntoView({ behavior: "smooth", block: "start" });

    // 스크롤 후 해당 질문의 첫 번째 입력 요소로 포커스 이동
    setTimeout(() => {
      // 질문 타입에 따라 포커스할 요소 선택
      let focusElement = null;

      switch (question.type) {
        case SURVEY_QUESTION_TYPE_YESORNO:
        case SURVEY_QUESTION_TYPE_MULTIPLECHOICE:
          // 라디오버튼이나 체크박스의 경우 첫 번째 항목에 포커스
          focusElement = questionEl.querySelector(
            'input[type="radio"], input[type="checkbox"]'
          );
          break;
        case SURVEY_QUESTION_TYPE_SCORE:
          // 점수 버튼의 경우 첫 번째 버튼에 포커스
          focusElement = questionEl.querySelector("button.scale-button");
          break;
        case SURVEY_QUESTION_TYPE_ESSAY:
          // 에세이의 경우 텍스트영역에 포커스
          focusElement = questionEl.querySelector("textarea");
          break;
        case SURVEY_QUESTION_TYPE_DATE:
          // 날짜 입력의 경우 날짜 필드에 포커스
          focusElement = questionEl.querySelector('input[type="date"]');
          break;
        case SURVEY_QUESTION_TYPE_SHORTANSWER:
          // 단답형의 경우 텍스트 입력에 포커스
          focusElement = questionEl.querySelector('input[type="text"]');
          break;
        case SURVEY_QUESTION_TYPE_YEAR:
          // 년도 선택의 경우 선택 요소에 포커스
          focusElement = questionEl.querySelector("select");
          break;
        default:
          break;
      }

      // 포커스 요소가 있으면 포커스 설정
      if (focusElement && !focusElement.disabled) {
        focusElement.focus();
      }
    }, 100); // 스크롤 완료 후 포커스를 위해 충분한 지연 추가
  }, []);

  // 이벤트 핸들러 - iframe 내에서 작동 중일 때 부모 페이지와 통신
  const handlePrevClick = useCallback(() => {
    if (props.inIframe) {
      window.parent.postMessage({ type: "PREV_CLICK" }, window.location.origin);
    } else {
      onClickPrev();
    }
  }, [props.inIframe, onClickPrev]);

  const handleNextClick = useCallback(() => {
    try {
      // 직접 유효성 검사 수행
      if (currentData && currentData.questions) {
        // 미응답 필수 질문 찾기
        const firstUnansweredQuestion = currentData.questions.find(
          (question) => {
            // 필수 질문이 아니면 검사 안 함
            if (!question.mandatory) {
              return false;
            }

            // 백엔드에서 disabled 처리된 질문은 검사 안 함
            if (question.answerdisabled === true) {
              return false;
            }

            // 유효하지 않은 질문 찾기
            return validQuestions[question.idquestion] !== true;
          }
        );

        // 미응답 질문이 있으면 알림 후 스크롤
        if (firstUnansweredQuestion) {
          alert(
            "모든 필수 질문에 형식을 맞춰서 답변해주세요.\nPlease make sure to respond to all required questions."
          );

          // 공통 함수 사용하여 미응답 질문으로 스크롤 및 포커스
          scrollToQuestionAndFocus(firstUnansweredQuestion);

          return; // 미응답 질문이 있으면 제출 중단
        }
      }

      // 모든 답변이 유효하면 제출 진행
      const allAnswers = {};
      if (currentData && currentData.questions) {
        currentData.questions.forEach((question) => {
          allAnswers[question.idquestion] = getAnswer(question);
        });
      }

      if (props.inIframe) {
        window.parent.postMessage(
          { type: "NEXT_CLICK", data: allAnswers },
          window.location.origin
        );
      } else {
        onClickNext(allAnswers);
      }
    } catch (error) {
      console.error("다음 버튼 클릭 처리 중 오류 발생:", error);
    }
  }, [
    props.inIframe,
    onClickNext,
    currentData,
    getAnswer,
    validQuestions,
    scrollToQuestionAndFocus,
  ]);

  // 객관식/다중선택 처리 - 서버 응답에 따라 유효성 업데이트
  const onToggleAnswer = (question, item, value) => {
    // 데이터 준비 - 단일/다중 선택에 따라 처리
    let data;

    if (!!question.multipleselect) {
      // 다중선택인 경우
      const list = !!getAnswer(question) ? getAnswer(question).split(",") : [];
      const ret = list.filter(
        (element) => Number(element) !== Number(item.orderno)
      );

      if (value === true) {
        ret.push(Number(item.orderno));
      }

      data = ret.join(",");
    } else {
      // 단일선택인 경우
      data = `${item.orderno}`;
    }

    // 답변 업데이트 후 서버 응답에 따라 유효성 상태 업데이트
    updateAnswer(data, question).then((success) => {
      if (success) {
        // 서버 응답이 성공이면 유효성 검사 실행
        const reasons = getReasons(question);
        const isValid = checkValidation(question, data, reasons);

        // 유효성 상태 업데이트
        setValidQuestions((prev) => ({
          ...prev,
          [question.idquestion]: isValid,
        }));

        // moveafteranswer이면 즉시 질문 목록 새로고침
        if (!!question.moveafteranswer && props.refreshQuestions) {
          props.refreshQuestions();
        } else {
          // 자동 스크롤 조건 확인 후 다음 질문으로 포커스 이동
          checkAutoFocus(question, item);
        }
      }
    });
  };

  // 자동으로 다음 질문으로 이동하는 함수
  const checkAutoFocus = (question, item) => {
    // 1. 다중선택이 아닌 경우에만 적용
    if (!!question.multipleselect) return;

    // 2. 주관식, 날짜형, 단답형 질문이 아닌 경우에만 적용
    if (
      [
        SURVEY_QUESTION_TYPE_ESSAY,
        SURVEY_QUESTION_TYPE_DATE,
        SURVEY_QUESTION_TYPE_SHORTANSWER,
      ].includes(question.type)
    )
      return;

    // 3. 보충 설명이 필요하지 않은 경우에만 적용
    if (item.isetc) return;

    // 4. 객관식이면서 이유 입력이 필요하면 자동 이동 안함
    if (
      question.type === SURVEY_QUESTION_TYPE_MULTIPLECHOICE &&
      !!question.inputreason &&
      item.reasonguide !== null
    )
      return;

    // 5. 예/아니오 형이면서 이유 입력이 필요하면 자동 이동 안함
    if (
      question.type === SURVEY_QUESTION_TYPE_YESORNO &&
      !!question.inputreason
    )
      return;

    // 현재 질문 인덱스 찾기
    if (!currentData || !currentData.questions) return;

    const currentIndex = currentData.questions.findIndex(
      (q) => q.idquestion === question.idquestion
    );

    // 다음 질문이 없으면 종료
    if (currentIndex === -1 || currentIndex >= currentData.questions.length - 1)
      return;

    // 다음 질문으로 스크롤
    setTimeout(() => {
      const nextQuestion = currentData.questions[currentIndex + 1];

      // 공통 함수 사용하여 다음 질문으로 스크롤 및 포커스
      scrollToQuestionAndFocus(nextQuestion);
    }, 100);
  };

  // 콜백 ref를 사용하여 DOM 요소 저장하는 함수
  const setQuestionRef = useCallback((questionId, element) => {
    if (element) {
      questionRefsRef.current[questionId] = element;
    }
  }, []);

  // 텍스트 입력 처리를 위한 함수 - 서버 응답에 따라 유효성 업데이트
  const handleTextInput = (value, question) => {
    updateAnswer(value, question).then((success) => {
      if (success) {
        // 서버 응답이 성공이면 유효성 검사 실행
        const reasons = getReasons(question);
        const isValid = checkValidation(question, value, reasons);

        // 유효성 상태 업데이트
        setValidQuestions((prev) => ({
          ...prev,
          [question.idquestion]: isValid,
        }));

        if (question.type === SURVEY_QUESTION_TYPE_YEAR) {
          // 년도 선택 질문의 경우 자동 이동
          checkAutoFocus(question, {});
        }
      }
    });
  };

  // 이유 입력 처리를 위한 함수 - 서버 응답에 따라 유효성 업데이트
  const handleReasonInput = (itemOrderno, value, question) => {
    updateReason(itemOrderno, value, question).then((success) => {
      if (success) {
        // 서버 응답이 성공이면 유효성 검사 실행
        const answer = getAnswer(question);
        const reasons = {
          ...getReasons(question),
          [itemOrderno]: value,
        };
        const isValid = checkValidation(question, answer, reasons);

        // 유효성 상태 업데이트
        setValidQuestions((prev) => ({
          ...prev,
          [question.idquestion]: isValid,
        }));
      }
    });
  };

  // 모든 질문 렌더링 (TailwindUI에서는 모든 질문을 표시)
  const renderAllQuestions = () => {
    if (
      !currentData ||
      !currentData.questions ||
      currentData.questions.length === 0
    ) {
      return null;
    }

    // 모든 질문을 순회하며 렌더링
    return currentData.questions.map((question, index) => {
      // 백엔드에서 제공하는 disabled 필드만 사용
      const isDisabled = question.answerdisabled === true;

      const disabledStyle = isDisabled
        ? "opacity-50 cursor-not-allowed pointer-events-none"
        : "";

      // 각 질문마다 렌더링
      return (
        <div
          key={question.idquestion}
          id={`question-container-${question.idquestion}`}
          data-question-id={question.idquestion}
          ref={(el) => setQuestionRef(question.idquestion, el)}
          className={`question-container mb-10 ${disabledStyle}`}
        >
          {/* 질문 제목 */}
          <p className="text-xl md:text-2xl font-normal mb-6">
            <strong>
              {currentData.survey.show_numbers && `${question.orderno}. `}
              {question.content}
            </strong>
            {!question.mandatory && (
              <span className="text-gray-500 mr-2">(Optional)</span>
            )}
          </p>

          {/* 질문 이미지 */}
          {question.image?.length > 0 && (
            <img
              className="description-image"
              src={questionImageUrl(question.image, "large")}
              alt={`질문 ${question.orderno} 이미지`}
            />
          )}

          {/* 질문 내용 - 질문 타입에 따라 다른 렌더링 함수 호출 */}
          {renderQuestionByType(question, isDisabled)}
        </div>
      );
    });
  };

  // 질문 타입에 따라 적절한 렌더링 함수 호출
  const renderQuestionByType = (question, isDisabled) => {
    // 현재 질문을 임시로 설정하고 렌더링
    const tempCurrentQuestion = question;
    const items = tempCurrentQuestion.items;

    // 현재 질문의 답변과 이유 가져오기
    const questionAnswer = getAnswer(tempCurrentQuestion);
    const questionReasons = getReasons(tempCurrentQuestion);

    switch (tempCurrentQuestion.type) {
      case SURVEY_QUESTION_TYPE_YESORNO:
        return (
          <div className="space-y-3">
            {items.map((item) => (
              <label
                key={item.idquestionitem}
                className="flex items-center space-x-3"
              >
                <input
                  type="radio"
                  name={`question-${tempCurrentQuestion.idquestion}`}
                  checked={!isDisabled && isSelected(item, tempCurrentQuestion)}
                  onChange={() =>
                    !isDisabled &&
                    onToggleAnswer(tempCurrentQuestion, item, true)
                  }
                  disabled={isDisabled}
                  className="w-5 h-5 accent-[#1890FF]"
                />
                <span className="text-lg md:text-xl">{item.content}</span>
              </label>
            ))}
          </div>
        );
      case SURVEY_QUESTION_TYPE_MULTIPLECHOICE:
        const hasReason = items.some(
          (item) =>
            item.isetc ||
            (tempCurrentQuestion.inputreason && item.reasonguide !== null)
        );
        return (
          <div
            className={hasReason ? "w-full" : "space-y-3"}
            key={tempCurrentQuestion.idquestion}
          >
            {items.map((item, index) => {
              const shouldInputReason = Boolean(
                (item.isetc && isSelected(item, tempCurrentQuestion)) ||
                  (tempCurrentQuestion.inputreason &&
                    item.reasonguide !== null &&
                    isSelected(item, tempCurrentQuestion))
              );
              const placeholderText =
                item.reasonguide ||
                "답변을 입력해주세요. (Please enter your answer.)";
              const itemReason = questionReasons[item.orderno] || "";

              return (
                <React.Fragment
                  key={`item-${tempCurrentQuestion.idquestion}-${item.orderno}`}
                >
                  <label
                    className={
                      hasReason
                        ? `option-wrapper ${
                            shouldInputReason ? "flex-norwap w-full" : ""
                          }`
                        : "flex items-center space-x-3"
                    }
                  >
                    <input
                      type={
                        tempCurrentQuestion.multipleselect === 1
                          ? "checkbox"
                          : "radio"
                      }
                      name={`question-${tempCurrentQuestion.idquestion}`}
                      checked={
                        !isDisabled && isSelected(item, tempCurrentQuestion)
                      }
                      onChange={
                        tempCurrentQuestion.multipleselect === 1
                          ? (e) =>
                              !isDisabled &&
                              onToggleAnswer(
                                tempCurrentQuestion,
                                item,
                                e.target.checked
                              )
                          : () =>
                              !isDisabled &&
                              onToggleAnswer(tempCurrentQuestion, item, true)
                      }
                      disabled={isDisabled}
                      className="w-5 h-5 primary-blue text-lg md:text-xl"
                    />
                    {hasReason ? (
                      item.content
                    ) : (
                      <span className="text-lg md:text-xl">{item.content}</span>
                    )}
                    {shouldInputReason && (
                      <input
                        type="text"
                        id={`question-${tempCurrentQuestion.idquestion}-item-${item.orderno}`}
                        className="additional-input"
                        placeholder={placeholderText}
                        value={itemReason}
                        onChange={(e) =>
                          handleReasonInput(
                            item.orderno,
                            e.target.value,
                            tempCurrentQuestion
                          )
                        }
                        rows="1"
                      />
                    )}
                  </label>
                  {hasReason && <div className="divider" />}
                </React.Fragment>
              );
            })}
          </div>
        );
      case SURVEY_QUESTION_TYPE_SCORE:
        const firstItem = items[0];
        const lastItem = items[items.length - 1];
        return (
          <>
            <div className="flex w-full justify-between space-x-2">
              {items.map((item) => (
                <button
                  key={item.idquestionitem}
                  className={`scale-button relative flex-1 flex items-center justify-center p-3 md:p-4 text-sm md:text-base border border-[#D9D9D9] ${
                    !isDisabled && isSelected(item, tempCurrentQuestion)
                      ? "bg-blue-500 text-white"
                      : "bg-[#FAFAFA]"
                  } shadow-sm rounded-md hover:bg-blue-500 hover:text-white focus:bg-blue-500 focus:text-white`}
                  onClick={() =>
                    !isDisabled &&
                    onToggleAnswer(tempCurrentQuestion, item, true)
                  }
                  disabled={isDisabled}
                >
                  {item.score}
                  {item.content?.length > 0 && (
                    <span className="tooltip">{item.content}</span>
                  )}
                </button>
              ))}
            </div>
            <div className="mb-4 p-4 rounded-md text-sm md:text-base text-gray-600 leading-[22px]">
              {firstItem.score}점 : {firstItem.content} - {lastItem.score}점 :{" "}
              {lastItem.content}
            </div>
          </>
        );
      case SURVEY_QUESTION_TYPE_ESSAY:
        return (
          <div className="relative">
            <textarea
              className="w-full p-3 border rounded-lg focus:ring focus:ring-blue-300 text-lg md:text-xl"
              rows="5"
              placeholder={
                tempCurrentQuestion.isconditional === 1 &&
                tempCurrentQuestion.conditiontype === "phoneno"
                  ? "휴대폰 번호를 입력해주세요. (- 없이 숫자만 입력) (Please enter your phone number without a dash (01012345678)."
                  : "답변을 입력하세요. (Please enter your answer.)"
              }
              value={!isDisabled ? questionAnswer || "" : ""}
              onChange={(e) => {
                if (!isDisabled) {
                  const value = e.target.value;
                  if (value.length > 300) return;
                  handleTextInput(value, tempCurrentQuestion);
                }
              }}
              disabled={isDisabled}
            ></textarea>
            <span id="char-counter" className="block mt-1 text-counter">
              {questionAnswer?.length || 0} / 300
            </span>
          </div>
        );
      case SURVEY_QUESTION_TYPE_DATE:
        return (
          <div className="w-full flex justify-center">
            <input
              type="date"
              className="date-input"
              value={!isDisabled ? questionAnswer || "" : ""}
              onChange={(e) => {
                if (!isDisabled) {
                  handleTextInput(e.target.value, tempCurrentQuestion);
                }
              }}
              disabled={isDisabled}
            />
          </div>
        );
      case SURVEY_QUESTION_TYPE_SHORTANSWER:
        const item = items[0];
        let errorMessage = null;
        if (questionAnswer.length > 0) {
          if (item.numberonly && !validateRealNumber(questionAnswer)) {
            errorMessage = "숫자만 입력해주세요. (Please enter only numbers.)";
          }
        }
        return (
          <>
            <div className="flex items-center justify-center gap-2 w-full">
              {/* 숫자 입력 필드 앞뒤로 텍스트 추가 */}
              <div className="input-wrapper w-full flex items-center space-x-2">
                {item.prefixguide && (
                  <span className="text-lg md:text-xl text-gray-600">
                    {item.prefixguide}
                  </span>
                )}
                <input
                  type="text"
                  className="input-field flex-grow text-lg md:text-xl p-2 border-b-2 border-gray-300 text-center focus:border-blue-500 focus:outline-none"
                  placeholder={
                    item.placeholder || "답변 입력 (Please enter your answer.)"
                  }
                  value={!isDisabled ? questionAnswer || "" : ""}
                  onChange={(e) => {
                    if (!isDisabled) {
                      handleTextInput(e.target.value, tempCurrentQuestion);
                    }
                  }}
                  disabled={isDisabled}
                />
                {item.suffixguide && (
                  <span className="text-lg md:text-xl text-gray-600">
                    {item.suffixguide}
                  </span>
                )}
              </div>
            </div>

            {/* 에러 메시지 - 숫자만 입력해야 하는 경우 */}
            {errorMessage && (
              <p className="error-message mt-2">{errorMessage}</p>
            )}

            {/* 글자 수 카운터 */}
            <span className="block mt-1 text-right text-sm text-gray-400">
              {(questionAnswer || "").length} / 100
            </span>
          </>
        );
      case SURVEY_QUESTION_TYPE_YEAR:
        const currentYear = new Date().getFullYear();
        const years = Array.from(
          { length: currentYear - 1900 + 1 },
          (_, i) => currentYear - i
        );
        const answerInt = questionAnswer ? parseInt(questionAnswer) : 0;
        const selectedYear =
          answerInt > 0 ? years.find((year) => year === answerInt) : null;

        return (
          <select
            className="select-box"
            value={selectedYear || 2000}
            onChange={(e) =>
              !isDisabled &&
              handleTextInput(e.target.value, tempCurrentQuestion)
            }
            disabled={isDisabled}
          >
            <option value="">선택하세요</option>
            {years.map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </select>
        );
      default:
        return null;
    }
  };

  // TailwindUI는 expand_sections가 true인 경우에만 사용됨
  if (!isExpandSections()) {
    return null;
  }

  return currentData ? (
    <>
      {/* 상단 네비게이션 바 */}
      <header className="w-full h-16 bg-white shadow-md flex items-center justify-center fixed top-0 left-0 z-10">
        <h2 className="text-lg md:text-xl font-medium">
          {currentData.section?.name || "설문조사 (Survey)"}
        </h2>
      </header>

      <div className="flex flex-col items-center min-h-screen p-4 pt-20">
        <div className="w-full sm:max-w-md md:max-w-[640px] bg-white p-6 sm:p-8 pt-6 pb-12 rounded-lg shadow-md">
          {/* 섹션 설명 */}
          {currentData.section?.commonquestion && (
            <div className="mb-6 p-4 border border-[#D9D9D9] bg-[#FAFAFA] rounded-md text-base font-normal leading-6">
              {currentData.section.commonquestion}
            </div>
          )}

          {/* 문항 내용 - 모든 질문을 렌더링 */}
          {renderAllQuestions()}

          {/* 버튼 영역 */}
          <div className="mt-10 flex justify-center space-x-4">
            <button
              className="w-1/2 border border-gray-300 bg-white text-lg md:text-xl py-3 rounded-lg hover:bg-gray-100"
              onClick={handlePrevClick}
            >
              이전 (Prev)
            </button>

            <button
              className="w-full text-white py-3 text-lg md:text-xl rounded-lg hover:bg-blue-700 primary-blue hover:bg-blue-700"
              onClick={handleNextClick}
            >
              {currentData.hasnext ? "다음 (Next)" : "제출하기 (Submit)"}
            </button>
          </div>
        </div>
      </div>
    </>
  ) : null;
};

export default AnswerQuestionTailwind;
