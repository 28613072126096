import React, { useState, useRef, useEffect } from "react";
import { useNavigate, useLocation } from "react-router";

import { useApi } from "../../context/ApiContext";
import { validateEamil, validatePassword } from "../../common/stringHelper";
import { useCommonPopup } from "../../context/CommonPopupContext";

const Terms = (props) => {
  const { action } = props;
  const api = useApi();
  const location = useLocation();
  const { alert } = useCommonPopup();
  const [data, setData] = useState();

  useEffect((_) => {
    getAgreement();
  }, []);

  const getAgreement = async () => {
    const result = await api.request("/agreements/", {
      params: { search: "terms" },
    });

    if (result?.status !== 200) {
      api.networkError(result, alert);
    } else {
      setData(result.data[0].content);
    }
  };

  return (
    <div className="body">
      <div className="login agreement">
        <div className="inwrap">
          <div>
            <ul>
              <li>
                <i className="icon-logo"></i>
                <div dangerouslySetInnerHTML={{ __html: data }}></div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Terms;
