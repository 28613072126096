import { useQuestionLogic } from "../../utils/questionUtils";
import { useEffect, useRef, useState, useCallback } from "react";
import {
  SURVEY_QUESTION_TYPE_YESORNO,
  SURVEY_QUESTION_TYPE_MULTIPLECHOICE,
  SURVEY_QUESTION_TYPE_SCORE,
  SURVEY_QUESTION_TYPE_ESSAY,
  SURVEY_QUESTION_TYPE_DATE,
  SURVEY_QUESTION_TYPE_SHORTANSWER,
  SURVEY_QUESTION_TYPE_YEAR,
} from "../../common/codeHelper";
import { questionImageUrl } from "../../common/imageHelper";
import ChoiceContent from "../../component/ChoiceContent";
import YesOrNoContent from "../../component/YesOrNoContent";
import ShortAnswerContent from "../../components/ShortAnswerContent";
import ScoreContent from "../../component/ScoreContent";
import YearContent from "../../component/YearContent";

const AnswerQuestion = (props) => {
  const {
    answer,
    reasons,
    checkOK,
    updateAnswer,
    updateReason,
    onSelectAnswer,
    isSelected,
    onClickPrev,
    onClickNext,
    getCurrentQuestion,
  } = useQuestionLogic(props);

  const [currentData, setCurrentData] = useState();
  const [currentQuestion, setCurrentQuestion] = useState(null);

  const imageRef = useRef();
  const [imageHeight, setImageHeight] = useState(100);
  const [imageKey, setImageKey] = useState(0);

  const updateImageHeight = useCallback(() => {
    if (!imageRef.current) return;
    const newHeight = (5 / 4) * imageRef.current.offsetWidth;
    setImageHeight(newHeight);
    setImageKey((prevKey) => prevKey + 1);
  }, []);

  useEffect(() => {
    updateImageHeight();
    window.addEventListener("resize", updateImageHeight);
    return () => window.removeEventListener("resize", updateImageHeight);
  }, [updateImageHeight]);

  useEffect(() => {
    if (
      currentData?.question?.idquestion !==
      props.currentData?.question?.idquestion
    ) {
      setCurrentData(props.currentData);
      setCurrentQuestion(props.currentData?.question);
      setImageKey((prevKey) => prevKey + 1);
    }
  }, [props.currentData, getCurrentQuestion]);

  const essayContent = () => {
    if (!currentQuestion) return null;

    return (
      <>
        <ul className="radio">
          <li>
            <textarea
              className={`full solo ${answer?.length === 300}`}
              id="textarea_auto"
              placeholder={
                currentQuestion.isconditional === 1 &&
                currentQuestion.conditiontype === "phoneno"
                  ? "휴대폰 번호를 입력해주세요 (- 없이 숫자만 입력)"
                  : "답변 입력"
              }
              rows="1"
              value={answer}
              onChange={onInputAnswer}
            />
            <p className="text_length">{answer?.length}/300</p>
          </li>
        </ul>
      </>
    );
  };

  const dateContent = () => {
    return (
      <>
        <input
          className={`big ${answer?.length > 0 ? "active" : ""}`}
          type="date"
          value={answer}
          onChange={onChangeDate}
        />
      </>
    );
  };

  const onInputAnswer = (e) => {
    var value = e.target.value;
    if (value.length > 300) return;
    updateAnswer(value);

    e.target.style.height = 0;
    e.target.style.height = 2 + e.target.scrollHeight + "px";
  };

  const onChangeDate = (e) => {
    var value = e.target.value;
    updateAnswer(value);
  };

  const onToggleAnswer = (item, value) => {
    if (!currentQuestion) return;

    if (currentQuestion.multipleselect === 1) {
      var list = !!answer ? answer.split(",") : [];
      var ret = list.filter(
        (element) => Number(element) !== Number(item.orderno)
      );
      if (value === true) ret.push(Number(item.orderno));
      var data = ret.join(",");
      updateAnswer(data);
    } else {
      var data = `${item.orderno}`;
      updateAnswer(data);
    }
  };

  const renderShortAnswerContent = () => {
    if (!currentQuestion || !currentData) return null;

    // ShortAnswerContent 컴포넌트에 currentQuestion을 전달
    const adaptedData = {
      ...currentData,
      question: currentQuestion,
    };

    return (
      <ShortAnswerContent
        currentData={adaptedData}
        answer={answer}
        updateAnswer={updateAnswer}
      />
    );
  };

  return !!currentData && !!currentQuestion ? (
    <div className="inwrap">
      <div className="questionnair_body">
        {currentData.section.commonquestion?.length > 0 && (
          <p className="bg_gray">{currentData.section.commonquestion}</p>
        )}
        <div className="bg_white">
          <h3>
            {currentData.survey.show_numbers && `${currentQuestion.orderno}. `}
            {currentQuestion.content}
          </h3>
          {currentQuestion?.image?.length > 0 && (
            <img
              key={imageKey}
              ref={imageRef}
              style={{ maxHeight: `${imageHeight}px`, objectFit: "cover" }}
              src={questionImageUrl(currentQuestion.image, "large")}
              onLoad={updateImageHeight}
            />
          )}
          {currentQuestion?.type === SURVEY_QUESTION_TYPE_YESORNO && (
            <YesOrNoContent
              currentData={{ ...currentData, question: currentQuestion }}
              isSelected={isSelected}
              onSelectAnswer={onSelectAnswer}
              reasons={reasons}
              updateReason={(itemOrderno, value) =>
                updateReason(itemOrderno, value, 500)
              }
            />
          )}
          {currentQuestion?.type === SURVEY_QUESTION_TYPE_MULTIPLECHOICE && (
            <ChoiceContent
              currentData={{ ...currentData, question: currentQuestion }}
              answer={answer}
              reasons={reasons}
              isSelected={isSelected}
              onToggleAnswer={onToggleAnswer}
              onSelectAnswer={onSelectAnswer}
              onInputReason={(itemOrderno, value) =>
                updateReason(itemOrderno, value, 500)
              }
            />
          )}
          {currentQuestion?.type === SURVEY_QUESTION_TYPE_SCORE && (
            <ScoreContent
              currentData={{ ...currentData, question: currentQuestion }}
              isSelected={isSelected}
              onSelectAnswer={onSelectAnswer}
            />
          )}
          {currentQuestion?.type === SURVEY_QUESTION_TYPE_ESSAY &&
            essayContent()}
          {currentQuestion?.type === SURVEY_QUESTION_TYPE_DATE && dateContent()}
          {currentQuestion?.type === SURVEY_QUESTION_TYPE_YEAR && (
            <YearContent answer={answer} updateAnswer={updateAnswer} />
          )}
          {currentQuestion?.type === SURVEY_QUESTION_TYPE_SHORTANSWER &&
            renderShortAnswerContent()}
        </div>
        <div className="but_wrap">
          {currentData.hasprev ? (
            <>
              <button
                type="button"
                className="secondary gray large "
                onClick={onClickPrev}
              >
                이전
              </button>
              <button
                type="button"
                className={`primary large ${!checkOK ? "disabled" : ""}`}
                disabled={!checkOK}
                onClick={() => onClickNext(answer)}
              >
                다음
              </button>
            </>
          ) : (
            <button
              type="button"
              className="primary large full"
              onClick={() => onClickNext(answer)}
            >
              시작
            </button>
          )}
        </div>
      </div>
    </div>
  ) : null;
};
export default AnswerQuestion;
