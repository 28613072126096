import {
  SURVEY_QUESTION_TYPE_ESSAY,
  SURVEY_QUESTION_TYPE_DATE,
  SURVEY_QUESTION_TYPE_MULTIPLECHOICE,
  SURVEY_QUESTION_TYPE_YESORNO,
  SURVEY_QUESTION_TYPE_SHORTANSWER,
} from "../common/codeHelper";
import { useState, useEffect, useRef, useCallback } from "react";
import {
  validatePhoneNumberWithHiphen,
  validateRealNumber,
} from "../common/stringHelper";
import { useApi } from "../context/ApiContext";

export const useQuestionLogic = (props, isPreview = false) => {
  const { currentData, accessToken, onPrev, onNext, api: propsApi } = props;
  // 항상 useApi 훅을 호출하고, props에서 전달된 api가 있으면 그것을 우선적으로 사용
  const contextApi = useApi();
  const api = propsApi || contextApi;

  // 각 질문별로 답변을 관리하기 위해 객체 형태로 변경
  const [answers, setAnswers] = useState({});

  // 각 질문별로 이유를 관리하기 위해 중첩 객체 형태로 변경
  const [allReasons, setAllReasons] = useState({});

  const [checkOK, setCheckOK] = useState(false);
  const [isLastQuestion, setIsLastQuestion] = useState(false);
  const timer = useRef(null);
  const [shouldCheck, setShouldCheck] = useState(false);

  // 이전 데이터를 저장하는 ref 추가
  const prevDataRef = useRef(null);
  // 현재 질문 ID를 저장하는 ref 추가
  const currentQuestionIdRef = useRef(null);
  // 현재 질문을 저장하는 ref 추가
  const currentQuestionRef = useRef(null);

  // 확장 섹션 사용 여부 확인 (새 API 구조 사용 여부)
  const isExpandSections = useCallback(() => {
    return !!currentData?.survey?.expand_sections;
  }, [currentData]);

  // 현재 질문 데이터 추출 (기존 API 구조와 새 API 구조 모두 지원)
  const getCurrentQuestion = useCallback(() => {
    // 이미 캐시된 질문이 있으면 그것을 반환
    if (currentQuestionRef.current && prevDataRef.current === currentData) {
      return currentQuestionRef.current;
    }

    if (!currentData) return null;

    let question = null;

    // 새 API 구조 (expand_sections가 true인 경우)
    if (isExpandSections()) {
      // questions 배열이 있고 questionorderno가 있는 경우
      if (currentData.questions && currentData.questionorderno) {
        question =
          currentData.questions.find(
            (q) => q.orderno === currentData.questionorderno
          ) || currentData.questions[0];
      }
      // questions 배열만 있는 경우 (TailwindUI용)
      else if (currentData.questions && currentData.questions.length > 0) {
        question = currentData.questions[0];
      }
    } else {
      // 기존 API 구조: question 객체가 직접 있는 경우
      question = currentData.question;
    }

    // 질문을 캐시에 저장
    currentQuestionRef.current = question;
    return question;
  }, [currentData, isExpandSections]);

  // 현재 질문 번호 가져오기
  const getCurrentQuestionOrdNo = useCallback(() => {
    if (!currentData) return null;

    // 새 API 구조 (expand_sections가 true인 경우)
    if (isExpandSections()) {
      // currentquestionorderno가 있는 경우
      if (currentData.currentquestionorderno) {
        return currentData.currentquestionorderno;
      }

      // questionorderno가 있는 경우
      if (currentData.questionorderno) {
        return currentData.questionorderno;
      }
    }

    // 기존 API 구조 또는 fallback: 현재 질문의 orderno 사용
    const currentQuestion = getCurrentQuestion();
    return currentQuestion?.orderno || null;
  }, [currentData, getCurrentQuestion, isExpandSections]);

  // 질문 ID 가져오기 (질문 객체 또는 ID를 인자로 받음)
  const getQuestionId = useCallback(
    (questionOrId) => {
      if (!questionOrId) {
        // 현재 질문 ID가 저장되어 있으면 그것을 사용
        if (currentQuestionIdRef.current) {
          return currentQuestionIdRef.current;
        }

        // 아니면 현재 질문을 가져와서 ID 추출
        const currentQuestion = getCurrentQuestion();
        return currentQuestion?.idquestion;
      }

      return typeof questionOrId === "object"
        ? questionOrId.idquestion
        : questionOrId;
    },
    [getCurrentQuestion]
  );

  // 데이터가 변경될 때만 상태 업데이트
  useEffect(() => {
    // 이전 데이터와 현재 데이터가 같으면 무시
    if (prevDataRef.current === currentData) return;
    if (!currentData) return;

    try {
      // 현재 질문 가져오기 (캐시 초기화)
      currentQuestionRef.current = null;
      const currentQuestion = getCurrentQuestion();
      if (!currentQuestion) return;

      // 현재 질문 ID 저장
      currentQuestionIdRef.current = currentQuestion.idquestion;

      const isLast =
        currentData.section?.orderno === currentData.totalsection &&
        currentQuestion.orderno === currentData.totalquestion;
      setIsLastQuestion(isLast);

      // 모든 질문에 대한 초기 상태 설정
      if (isExpandSections() && currentData.questions) {
        const newAnswers = {};
        const newReasons = {};

        currentData.questions.forEach((question) => {
          newAnswers[question.idquestion] = question.answercontent || "";
          newReasons[question.idquestion] = question.answerreason
            ? JSON.parse(question.answerreason)
            : {};
        });

        setAnswers(newAnswers);
        setAllReasons(newReasons);
      } else {
        // 기존 API 구조 지원
        setAnswers({
          [currentQuestion.idquestion]: currentQuestion.answercontent || "",
        });
        setAllReasons({
          [currentQuestion.idquestion]: currentQuestion.answerreason
            ? JSON.parse(currentQuestion.answerreason)
            : {},
        });
      }

      // 기본적으로 필수가 아닌 경우 체크 OK
      setCheckOK(currentQuestion.mandatory !== 1);

      // 현재 데이터를 이전 데이터로 저장
      prevDataRef.current = currentData;
    } catch (error) {
      console.error("데이터 처리 오류:", error);
    }
  }, [currentData, isExpandSections]);

  // 현재 활성화된 질문의 답변 가져오기
  const getAnswer = useCallback(
    (questionOrId) => {
      const questionId = getQuestionId(questionOrId);
      return answers[questionId] || "";
    },
    [answers, getQuestionId]
  );

  // 현재 활성화된 질문의 이유 가져오기
  const getReasons = useCallback(
    (questionOrId) => {
      const questionId = getQuestionId(questionOrId);
      return allReasons[questionId] || {};
    },
    [allReasons, getQuestionId]
  );

  // 유효성 검사 함수 메모이제이션
  const checkValidation = useCallback((question, answer, reasons) => {
    if (!question) return false;
    if (question.mandatory !== 1) return true;

    // 내부 isSelected 함수 구현 (순환 참조 방지)
    const isSelectedInternal = (element) => {
      if (question.multipleselect === 1) {
        const list = !!answer ? answer.split(",") : [];
        return list.some((item) => Number(item) === Number(element.orderno));
      } else {
        return answer === `${element.orderno}`;
      }
    };

    const items = question.items.filter(isSelectedInternal);
    const etcs = items.filter((element) => element.isetc === 1);

    if (etcs.length > 0) {
      return Object.values(reasons).some((value) => value?.length > 0);
    }
    if (question.isconditional === 1 && question.conditiontype === "phoneno") {
      return validatePhoneNumberWithHiphen(answer);
    }
    if (question.multipleselect === 1) {
      const selectedItems = question.items.filter(isSelectedInternal);
      return selectedItems.every(
        (item) => !item.isetc || reasons[item.orderno]?.length > 0
      );
    }
    if (question.type === SURVEY_QUESTION_TYPE_SHORTANSWER) {
      if (question.items[0]?.numberonly && !validateRealNumber(answer)) {
        return false;
      }
      if (answer.length > 100) {
        return false;
      }
    }
    return !!answer;
  }, []);

  // 답변이나 이유가 변경될 때만 유효성 검사 실행
  useEffect(() => {
    if (!currentData) return;

    try {
      // 현재 질문 ID가 없으면 무시
      if (!currentQuestionIdRef.current) return;

      // 현재 질문의 답변과 이유 가져오기
      const currentAnswer = answers[currentQuestionIdRef.current] || "";
      const currentReasons = allReasons[currentQuestionIdRef.current] || {};

      // 현재 질문 가져오기
      const currentQuestion = getCurrentQuestion();
      if (!currentQuestion) return;

      // 유효성 검사 실행
      setCheckOK(
        checkValidation(currentQuestion, currentAnswer, currentReasons)
      );
    } catch (error) {
      console.error("유효성 검사 오류:", error);
    }
  }, [answers, allReasons, checkValidation]);

  // answerQuestion 함수를 먼저 선언
  const answerQuestion = useCallback(async (question, answer, reasons) => {
    if (!currentData || !currentData.section) {
      console.error("현재 데이터 또는 섹션 정보가 없습니다.");
      return false;
    }

    try {
      // 전화번호 형식 처리 (하이픈 제거)
      let processedAnswer = answer;
      if (
        question.isconditional === 1 &&
        question.conditiontype === "phoneno"
      ) {
        processedAnswer = answer.replace(/-/g, "");
      }

      // 기존 API 엔드포인트 사용
      const response = await api.request("/surveys/answer_question/", {
        method: "POST",
        params: {
          accesstoken: accessToken,
          sectionorderno: currentData.section.orderno,
          questionorderno: question.orderno,
          answercontent: processedAnswer,
          answerreason: reasons,
        },
      });

      // 응답 상태 코드가 204인 경우 성공으로 간주
      return response.status === 204;
    } catch (error) {
      console.error("답변 제출 중 오류 발생:", error);
      return false;
    }
  });

  const updateAnswer = useCallback(
    (data, questionOrId = null, timeout = 0) => {
      const questionId = getQuestionId(questionOrId);

      if (!questionId) return Promise.resolve(false);

      setAnswers((prev) => ({
        ...prev,
        [questionId]: data,
      }));

      setShouldCheck(true); // 답변이 업데이트될 때 체크 플래그를 설정

      if (isPreview || !currentData?.section) {
        return Promise.resolve(true); // 프리뷰 모드에서는 항상 성공으로 처리
      }

      // Promise를 반환하여 호출자가 결과를 기다릴 수 있도록 함
      return new Promise((resolve) => {
        if (timer.current) clearTimeout(timer.current);
        timer.current = setTimeout(async () => {
          try {
            // 질문 객체 찾기
            const question =
              questionOrId && typeof questionOrId === "object"
                ? questionOrId
                : isExpandSections() && currentData?.questions
                ? currentData.questions.find((q) => q.idquestion === questionId)
                : getCurrentQuestion();

            if (question && currentData?.section) {
              const currentReasons = allReasons[questionId] || {};
              const result = await answerQuestion(
                question,
                data,
                JSON.stringify(currentReasons)
              );
              resolve(result); // API 호출 결과 반환
            } else {
              resolve(false); // 질문이나 섹션이 없으면 실패로 처리
            }
          } catch (error) {
            console.error("답변 업데이트 중 오류 발생:", error);
            resolve(false); // 오류 발생 시 실패로 처리
          }
        }, timeout);
      });
    },
    [
      isPreview,
      allReasons,
      getQuestionId,
      isExpandSections,
      currentData,
      getCurrentQuestion,
      answerQuestion,
    ]
  );

  const updateReason = useCallback(
    (itemOrderno, data, questionOrId = null, timeout = 0) => {
      const questionId = getQuestionId(questionOrId);

      if (!questionId) return Promise.resolve(false);

      setAllReasons((prev) => {
        const currentReasons = prev[questionId] || {};
        return {
          ...prev,
          [questionId]: {
            ...currentReasons,
            [itemOrderno]: data,
          },
        };
      });

      if (isPreview || !currentData?.section) {
        return Promise.resolve(true); // 프리뷰 모드에서는 항상 성공으로 처리
      }

      // Promise를 반환하여 호출자가 결과를 기다릴 수 있도록 함
      return new Promise((resolve) => {
        if (timer.current) clearTimeout(timer.current);
        timer.current = setTimeout(async () => {
          try {
            // 질문 객체 찾기
            const question =
              questionOrId && typeof questionOrId === "object"
                ? questionOrId
                : isExpandSections() && currentData?.questions
                ? currentData.questions.find((q) => q.idquestion === questionId)
                : getCurrentQuestion();

            if (question && currentData?.section) {
              const currentAnswer = `${itemOrderno}`;
              const updatedReasons = {
                ...(allReasons[questionId] || {}),
                [itemOrderno]: data,
              };
              const result = await answerQuestion(
                question,
                currentAnswer,
                JSON.stringify(updatedReasons)
              );
              resolve(result);
            } else {
              resolve(false); // 질문이나 섹션이 없으면 실패로 처리
            }
          } catch (error) {
            console.error("이유 업데이트 중 오류 발생:", error);
            resolve(false); // 오류 발생 시 실패로 처리
          }
        }, timeout);
      });
    },
    [
      isPreview,
      answers,
      allReasons,
      getQuestionId,
      isExpandSections,
      currentData,
      getCurrentQuestion,
      answerQuestion,
    ]
  );

  const isSelected = useCallback(
    (item, questionOrId = null) => {
      const questionId = getQuestionId(questionOrId);

      if (!questionId) return false;

      // 질문 객체 찾기
      const question =
        questionOrId && typeof questionOrId === "object"
          ? questionOrId
          : isExpandSections() && currentData?.questions
          ? currentData.questions.find((q) => q.idquestion === questionId)
          : getCurrentQuestion();

      if (!question) return false;

      const answer = answers[questionId] || "";

      if (question.multipleselect === 1) {
        const list = !!answer ? answer.split(",") : [];
        return list.some((element) => Number(element) === Number(item.orderno));
      } else {
        return answer === `${item.orderno}`;
      }
    },
    [answers, getQuestionId, isExpandSections, currentData, getCurrentQuestion]
  );

  const onSelectAnswer = useCallback(
    (item, questionOrId = null) => {
      const questionId = getQuestionId(questionOrId);

      if (!questionId) return;

      // 질문 객체 찾기
      const question =
        questionOrId && typeof questionOrId === "object"
          ? questionOrId
          : isExpandSections() && currentData?.questions
          ? currentData.questions.find((q) => q.idquestion === questionId)
          : getCurrentQuestion();

      if (!question) return;

      const answer = answers[questionId] || "";

      let data;
      if (question.multipleselect === 1) {
        const list = !!answer ? answer.split(",") : [];
        const ret = list.filter(
          (element) => Number(element) === Number(item.orderno)
        );
        if (ret?.length === 0) list.push(Number(item.orderno));
        data = list.join(",");
      } else {
        data = `${item.orderno}`;
      }

      updateAnswer(data, questionId, 0);
    },
    [
      answers,
      getQuestionId,
      isExpandSections,
      currentData,
      getCurrentQuestion,
      updateAnswer,
    ]
  );

  // 자동 진행 로직 - 의존성 배열 최적화
  useEffect(() => {
    if (!shouldCheck || !currentData) return;

    try {
      // 현재 질문 ID가 없으면 무시
      if (!currentQuestionIdRef.current) {
        setShouldCheck(false);
        return;
      }

      // 현재 질문의 답변 가져오기
      const currentAnswer = answers[currentQuestionIdRef.current] || "";

      if (currentAnswer !== "") {
        // 현재 질문 가져오기
        const currentQuestion = getCurrentQuestion();
        if (!currentQuestion) {
          setShouldCheck(false);
          return;
        }

        const isLastPage = currentData.currentpage === currentData.totalpage;
        const shouldAutoAdvance =
          !isLastPage &&
          currentQuestion.items.some((item) =>
            isSelected(item, currentQuestionIdRef.current)
          ) &&
          currentQuestion.multipleselect !== 1 &&
          ![
            SURVEY_QUESTION_TYPE_ESSAY,
            SURVEY_QUESTION_TYPE_DATE,
            SURVEY_QUESTION_TYPE_SHORTANSWER,
          ].includes(currentQuestion.type) &&
          !currentQuestion.items.find(
            (item) => item.orderno === Number(currentAnswer)
          )?.isetc &&
          !(
            currentQuestion.type === SURVEY_QUESTION_TYPE_MULTIPLECHOICE &&
            currentQuestion.inputreason === 1 &&
            currentQuestion.items.find(
              (item) => item.orderno === Number(currentAnswer)
            )?.reasonguide !== null
          ) &&
          !(
            currentQuestion.type === SURVEY_QUESTION_TYPE_YESORNO &&
            currentQuestion.inputreason === 1
          );

        if (shouldAutoAdvance) {
          onNext(currentAnswer);
        }
      }
    } catch (error) {
      console.error("자동 진행 처리 오류:", error);
    } finally {
      setShouldCheck(false); // 체크 완료 후 플래그 리셋
    }
  }, [shouldCheck, answers, isSelected, onNext]);

  return {
    // 현재 질문의 답변과 이유 (하위 호환성 유지)
    get answer() {
      try {
        // 현재 질문 ID가 있으면 그것을 사용
        if (currentQuestionIdRef.current) {
          return answers[currentQuestionIdRef.current] || "";
        }

        // 아니면 현재 질문을 가져와서 ID 추출
        const currentQuestion = getCurrentQuestion();
        return currentQuestion ? answers[currentQuestion.idquestion] || "" : "";
      } catch (error) {
        console.error("answer getter 오류:", error);
        return "";
      }
    },
    get reasons() {
      try {
        // 현재 질문 ID가 있으면 그것을 사용
        if (currentQuestionIdRef.current) {
          return allReasons[currentQuestionIdRef.current] || {};
        }

        // 아니면 현재 질문을 가져와서 ID 추출
        const currentQuestion = getCurrentQuestion();
        return currentQuestion
          ? allReasons[currentQuestion.idquestion] || {}
          : {};
      } catch (error) {
        console.error("reasons getter 오류:", error);
        return {};
      }
    },

    // 새로운 함수들
    getAnswer,
    getReasons,
    answers,
    allReasons,
    checkOK,
    isLastQuestion,
    updateAnswer,
    updateReason,
    onSelectAnswer,
    isSelected,
    onClickPrev: onPrev,
    onClickNext: onNext,
    getCurrentQuestion,
    getCurrentQuestionOrdNo,
    isExpandSections,
    checkValidation,
  };
};
