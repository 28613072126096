import React from "react";
import { validateRealNumber } from "../common/stringHelper";

const ShortAnswerContent = ({ currentData, answer, updateAnswer }) => {
  const item = currentData.question.items[0]; // 단답형은 항상 하나의 item만 가짐
  let errorMessage = null;
  if (answer.length > 0) {
    if (item.numberonly && !validateRealNumber(answer)) {
      errorMessage = "숫자만 입력할 수 있습니다.";
    } else if (answer.length > 100) {
      errorMessage = `${answer.length} / 100`;
    }
  }

  return (
    <ul className="radio short-answer-content">
      <li>
        {item.prefixguide && (
          <span className="guide prefix">{item.prefixguide}</span>
        )}

        <span className="textarea-wrapper">
          <textarea
            className={`solo ${errorMessage ? "danger" : ""}`}
            id="textarea_auto"
            placeholder={item.placeholder || "답변을 입력하세요"}
            rows="1"
            value={answer}
            onChange={(e) => updateAnswer(e.target.value)}
            type={item.numberonly ? "number" : "text"}
          />
          {errorMessage && <p className="error-message">{errorMessage}</p>}
        </span>

        {item.suffixguide && (
          <span className="guide suffix">{item.suffixguide}</span>
        )}
      </li>
    </ul>
  );
};

export default ShortAnswerContent;
