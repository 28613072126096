import $ from "jquery";
import { useEffect, useRef, useState, useMemo } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useApi } from "../../../context/ApiContext";

import SectionContent from "./SectionContent";
import QuestionContent from "./QuestionContent";
import { useSurvey } from "../../../context/SurveyContext";
import SurveyScalePopup from "../popup/SurveyScalePopup";
import toast from "react-hot-toast";
import { useCommonPopup } from "../../../context/CommonPopupContext";
import {
  SURVEY_STATUS_CLOSED,
  SURVEY_STATUS_WRITING,
} from "../../../common/codeHelper";
import SectionIndex from "./SectionIndex";
import QuestionIndex from "./QuestionIndex";

const SurveyContent = (props) => {
  const navigate = useNavigate();
  const { popup, confirm, alert } = useCommonPopup();

  const api = useApi();
  const survey = useSurvey();

  const [surveyName, setSurveyName] = useState(null);
  const surveyNameOld = useRef(null);
  const surveyNameRef = useRef();

  const saveTimer = useRef();
  const indexRefs = useRef();
  const scrollTimer = useRef(null);

  const [grab, setGrab] = useState(null);
  const [draggable, setDraggable] = useState(false);

  const [selectedItem, setSelectedItem] = useState(null);
  const oldSelectedItem = useRef();
  const isLock = useRef();

  const itemListRef = useRef();
  const scrollSpeed = useRef(0);
  const scrollPos = useRef(0);
  const scrollDir = useRef(0);
  const scrollAccelerator = useRef(0);

  const expandSections = useMemo(
    () => survey.surveyData?.expand_sections ?? false,
    [survey.surveyData]
  );
  const showNumbers = useMemo(
    () => survey.surveyData?.show_numbers ?? true,
    [survey.surveyData]
  );

  /*=========================================================
   *  useEffect
   =========================================================*/

  useEffect((_) => {
    // getSurvey(null, null)

    return () => {
      clearTimeout(saveTimer.current);
      clearTimeout(scrollTimer.current);
    };
  }, []);

  useEffect(
    (_) => {
      if (survey.surveyData === null) return;

      setSurveyName(survey.surveyData.name);
    },
    [survey.surveyData]
  );

  useEffect(
    (_) => {
      if (!!survey.showError) {
        if (survey.showError.type === "survey") {
          setTimeout(() => {
            surveyNameRef.current?.focus();
          }, 200);
        } else if (survey.showError.type === "section") {
          const section = survey.getSection(Number(survey.showError.id));
          const index = survey.itemList.indexOf(section);
          survey.selectItem(index);
        } else if (survey.showError.type === "question") {
          const question = survey.getQuestion(Number(survey.showError.id));
          const index = survey.itemList.indexOf(question);
          survey.selectItem(index);
        }
      }
    },
    [survey.showError]
  );

  useEffect(
    (_) => {
      if (survey.surveyData === null) return;

      setIndexRefs(survey.itemList);
    },
    [survey.itemList]
  );

  useEffect(
    (_) => {
      if (survey.selectedItem === selectedItem) return;
      setSelectedItem(survey.selectedItem);
    },
    [survey.selectedItem]
  );

  useEffect(
    (_) => {
      if (surveyNameOld.current === surveyName) return;

      surveyNameOld.current = surveyName;
      survey.updateSurveyName(surveyName);
    },
    [surveyName]
  );

  useEffect(
    (_) => {
      if (selectedItem == null) return;
      if (indexRefs == null || selectedItem?.listIndex === null) return;
      if (selectedItem?.listIndex === null) return;
      if (
        oldSelectedItem.current != null &&
        oldSelectedItem.current?.listIndex == selectedItem.listIndex
      )
        return;

      var id = `${selectedItem?.listIndex}`;
      if (selectedItem?.idsection != null) {
        if (selectedItem.listIndex > 0 && selectedItem.questions?.length > 0)
          id = `${selectedItem.questions[0].listIndex}`;
      }
      if (indexRefs[id] !== null && indexRefs[id]?.current !== null) {
        indexRefs[id]?.current.scrollIntoView({
          block: "nearest",
          inline: "nearest",
        });
      }
      oldSelectedItem.current = selectedItem;
    },
    [selectedItem]
  );

  /*=========================================================
   *  Data
   =========================================================*/
  const getSurvey = async (idsection, idquestion) => {
    const surveyData = await survey.getSurvey(idsection, idquestion);
  };

  const updateSurvey = async (field, value) => {
    const result = await api.request(`/surveys/${survey.surveyId}/`, {
      method: "patch",
      params: { [field]: value },
    });

    if (result?.status === 200) {
      survey.updateSurvey(field, value);
    } else {
      api.networkError(result, alert);
    }
  };

  const updateSurveyName = (name) => updateSurvey("name", name);

  // 설문 펼치기 설정 업데이트 함수
  const handleExpandSectionsChange = (e) => {
    updateSurvey("expand_sections", e.target.checked);
  };

  // 번호 매기기 설정 업데이트 함수
  const handleShowNumbersChange = (e) => {
    updateSurvey("show_numbers", e.target.checked);
  };

  const updateSectionOrder = async (idsection, orderno) => {
    const result = await api.post("updateSectionOrder", {
      section: idsection,
      orderno: orderno,
    });
    return result?.data?.result == "ok";
  };

  const updateQuestionOrder = async (idquestion, idsection, orderno) => {
    const result = await api.post("updateQuestionOrder", {
      question: idquestion,
      section: idsection,
      orderno: orderno,
    });
    return result?.data?.result == "ok";
  };

  /*=========================================================
   *  Handler
   =========================================================*/
  const setIndexRefs = (list) => {
    list.forEach((item, index) => {
      const id = `${index}`;
      if (!indexRefs[id]) {
        indexRefs[`${index}`] = { current: null };
      }
    });
  };

  const onChangeSurveyName = (e) => {
    var value = e.target.value;

    if (surveyNameOld.current === value) return;
    setSurveyName(value);

    if (!!saveTimer.current) clearTimeout(saveTimer.current);
    saveTimer.current = setTimeout(() => {
      updateSurveyName(value);
    }, survey.saveTimeout);
  };

  const onClickIndex = (index) => {
    survey.selectItem(index);
  };

  const onClickAddSection = async () => {
    if (isLock.current) return;

    isLock.current = true;

    const result = await api.request("/sections/", {
      method: "post",
      params: {
        survey: survey.surveyId,
      },
    });

    if (result?.status !== 201) {
      api.networkError(result, alert);
      isLock.current = false;
    } else {
      await getSurvey(result.data.section, null);
      isLock.current = false;
    }
  };

  const onClickDeleteSection = async (section) => {
    if (isLock.current) return;

    isLock.current = true;

    const result = await api.request(`/sections/${section.idsection}/`, {
      method: "delete",
    });

    if (result?.status !== 204) {
      await api.networkError(result, alert);
      isLock.current = false;
    } else {
      await getSurvey(selectedItem.idsection, selectedItem.idquestion);
      toast.success(`섹션이 삭제되었습니다.`);
      isLock.current = false;
    }
  };

  const onClickAddQuestion = async () => {
    if (isLock.current) return;

    isLock.current = true;

    const section = survey.getSection(
      survey.selectedItem?.idsection ?? survey.selectedItem?.sectionId
    );
    if (section == null) return;

    const result = await api.request("/questions/", {
      method: "post",
      params: {
        section: section.idsection,
      },
    });

    if (result?.status !== 201) {
      api.networkError(result, alert);
      isLock.current = false;
    } else {
      await getSurvey(section.idsection, result.data.question);
      isLock.current = false;
    }
  };

  const onClickDeleteQuestion = async (question) => {
    if (isLock.current) return;

    isLock.current = true;

    const result = await api.post("deleteQuestion", {
      question: question.idquestion,
    });

    if (result?.data?.result !== "ok") {
      await api.networkError(result, alert);
      isLock.current = false;
    } else {
      await getSurvey();
      toast.success(`문항이 삭제되었습니다.`);
      isLock.current = false;
    }
  };

  const onClickLoadStandard = async () => {
    const result = await popup(<SurveyScalePopup />, {
      container: "survey",
      name: "SurveyScalePopup",
      style: "survey_scale",
      title: "표준화된 척도",
      useContainer: false,
    });
    if (result != null) {
      onSelectedScale(result);
    }
  };

  const onSelectedScale = async (scale) => {
    if (!scale) return true;

    var result = null;

    if (scale.custom === 1) {
      result = await api.request("/sections/load_custom_scale/", {
        method: "post",
        params: {
          survey: survey.surveyId,
          customscale: scale.idcustomscale,
        },
      });
    } else {
      result = await api.request("/sections/load_scale/", {
        method: "post",
        params: {
          survey: survey.surveyId,
          scale: scale.idscale,
        },
      });
    }

    if (result?.status !== 201) {
      api.networkError(result, alert);
    } else {
      await getSurvey(result.data.section, null);
    }
    return true;
  };

  /*=========================================================
   *  Handler
   =========================================================*/

  const onDragOver = (e) => {
    e.preventDefault();

    var top = itemListRef.current.getBoundingClientRect().top;
    var y = e.clientY - top;
    var dir = y - scrollPos.current;

    if (dir != 0 && dir != scrollDir.current) {
      scrollSpeed.current = 1;
      scrollAccelerator.current = 1;
    } else {
      scrollAccelerator.current *= 1.2;
      scrollSpeed.current += scrollAccelerator.current;
      if (scrollSpeed.current > 200) scrollSpeed.current = 200;
    }

    const scrollTriggerHeight = 60;
    if (scrollDir.current < 0 && y < scrollTriggerHeight) {
      if (itemListRef.current.scrollTop > 0) {
        itemListRef.current.scrollTop =
          itemListRef.current.scrollTop - scrollSpeed.current;
      }
    } else if (
      scrollDir.current > 0 &&
      y > itemListRef.current.offsetHeight - scrollTriggerHeight
    ) {
      if (itemListRef.current.scrollTop < itemListRef.current.scrollHeight) {
        itemListRef.current.scrollTop =
          itemListRef.current.scrollTop + scrollSpeed.current;
      }
    }
    scrollPos.current = y;
    if (dir != 0) scrollDir.current = dir;
  };

  const onDragStart = (e) => {
    if (!draggable) {
      e.preventDefault();
      return;
    }

    var top = itemListRef.current.getBoundingClientRect().top;
    var y = e.clientY - top;
    scrollPos.current = y;
    scrollSpeed.current = -1;
    setGrab(e.target);

    e.target.classList.add("grabbing");
    e.dataTransfer.effectAllowed = "move";
    e.dataTransfer.setData("text/html", e.target);
  };

  const onDragEnd = (e) => {
    e.target.classList.remove("grabbing");
    e.dataTransfer.dropEffect = "move";
    setGrab(null);
  };

  const onDrop = async (e) => {
    let grabPosition = Number(grab.dataset.position);
    let targetPosition = Number(e.target.dataset.position);

    if (targetPosition === -1) return;

    if (isNaN(grabPosition) || isNaN(targetPosition)) return;

    const grabItem = survey.itemList[grabPosition];
    const targetItem = survey.itemList[targetPosition];

    if (!grabItem || !targetItem) return;

    if (!!grabItem.idsection) {
      const section = !!targetItem.idsection
        ? targetItem
        : survey.getSection(targetItem.sectionId);
      var result = await updateSectionOrder(
        grabItem.idsection,
        section.orderno
      );
      if (result) {
        await getSurvey();
      }
    } else {
      const section = !!targetItem.idsection
        ? targetItem
        : survey.getSection(targetItem.sectionId);
      const question = !!targetItem.idquestion ? targetItem : null;
      //idquestion, idsection, orderno
      var result = await updateQuestionOrder(
        grabItem.idquestion,
        section.idsection,
        question?.orderno ?? 1
      );
      if (result) {
        await getSurvey();
      }
    }
  };

  const ItemIndex = () => {
    return (
      <div
        className={`question_list ${
          survey.surveyData?.status !== SURVEY_STATUS_WRITING ? "disabled" : ""
        }`}
      >
        <ul id="survey-index" ref={itemListRef}>
          {survey.itemList?.map((item, index) => (
            <div key={`survey-index-${index}`}>
              {!!item.idsection ? (
                <SectionIndex
                  survey={survey}
                  section={item}
                  indexRefs={indexRefs}
                  onDragOver={onDragOver}
                  onDragStart={onDragStart}
                  onDragEnd={onDragEnd}
                  onDrop={onDrop}
                  dragging={!!grab}
                  setDraggable={setDraggable}
                  onClickIndex={onClickIndex}
                  onClickDeleteSection={onClickDeleteSection}
                  expandSections={expandSections}
                  showNumbers={showNumbers}
                />
              ) : (
                <QuestionIndex
                  survey={survey}
                  question={item}
                  indexRefs={indexRefs}
                  onDragOver={onDragOver}
                  onDragStart={onDragStart}
                  onDragEnd={onDragEnd}
                  onDrop={onDrop}
                  dragging={!!grab}
                  setDraggable={setDraggable}
                  onClickIndex={onClickIndex}
                  onClickDeleteQuestion={onClickDeleteQuestion}
                  showNumbers={showNumbers}
                />
              )}
            </div>
          ))}
        </ul>
        {survey.surveyData?.status === SURVEY_STATUS_WRITING ? (
          <div className="qlist_foot">
            <button className="text_only" onClick={onClickAddQuestion}>
              문항 추가
            </button>
            <button className="text_only" onClick={onClickLoadStandard}>
              척도 불러오기
            </button>
            <button className="text_only" onClick={onClickAddSection}>
              섹션추가
            </button>
          </div>
        ) : null}
      </div>
    );
  };

  return (
    <div className="container">
      <div className="inwrap">
        <div className="full gap">
          <section className="">
            <div className="search_wrap">
              <input
                type="text"
                ref={surveyNameRef}
                className={`full big ${
                  survey.hasError(`survey-${survey.surveyId}`) ? "error" : ""
                }`}
                placeholder="제목을 입력해주세요."
                value={surveyName ?? ""}
                onChange={onChangeSurveyName}
                disabled={survey.surveyData?.status === SURVEY_STATUS_CLOSED}
              />
            </div>

            {/* 설문 설정 옵션 */}
            <div
              style={{
                display: "flex",
                gap: "24px",
                paddingLeft: "24px",
                paddingBottom: "20px",
              }}
            >
              <div style={{ display: "flex", gap: "8px" }}>
                <span>설문 펼치기</span>
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={expandSections}
                    onChange={handleExpandSectionsChange}
                    disabled={
                      survey.surveyData?.status !== SURVEY_STATUS_WRITING
                    }
                  />
                  <span className="slider round"></span>
                </label>
              </div>
              <div style={{ display: "flex", gap: "8px" }}>
                <span>번호 매기기</span>
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={showNumbers}
                    onChange={handleShowNumbersChange}
                    disabled={
                      survey.surveyData?.status !== SURVEY_STATUS_WRITING
                    }
                  />
                  <span className="slider round"></span>
                </label>
              </div>
            </div>
          </section>
          <div className="qustion_wrap">
            {ItemIndex()}
            <div className="question">
              {!!survey.selectedItem?.idsection && (
                <SectionContent
                  section={survey.selectedItem}
                  showError={survey.showError}
                  showNumbers={showNumbers}
                />
              )}
              {!!survey.selectedItem?.idquestion && (
                <QuestionContent
                  section={survey.getSection(survey.selectedItem.sectionId)}
                  question={survey.selectedItem}
                  showError={survey.showError}
                  showNumbers={showNumbers}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SurveyContent;
