import { useEffect, useState } from "react";

const AnswerSection = (props) => {
  const { currentData, onPrev, onNext } = props;
  const [agree, setAgree] = useState(true);

  useEffect(
    (_) => {
      if (
        currentData.section.hasconditionquestion === 1 &&
        currentData.section.agreement?.length > 0
      ) {
        setAgree(false);
      }
    },
    [props]
  );

  const onClickPrev = () => {
    onPrev();
  };

  const onClickNext = () => {
    onNext(null);
  };

  const onChangeAgree = (e) => {
    setAgree(e.target.checked);
  };

  return !!currentData ? (
    <div className="inwrap start">
      <div className="questionnair_body">
        <div></div>
        <h3 className="c_primary_6">
          {currentData.survey.show_numbers &&
            `${currentData.section.orderno}. `}
          {currentData.section.name}
        </h3>
        <h4>{currentData.section.description}</h4>
        {currentData.section.commonquestion?.length > 0 && (
          <p className="bg_gray" style={{ whiteSpace: "pre-line" }}>
            {currentData.section.commonquestion}
          </p>
        )}
        {currentData.section.hasconditionquestion === 1 &&
          currentData.section.agreement?.length > 0 && (
            <>
              <p className="bg_gray" style={{ whiteSpace: "pre-line" }}>
                {currentData.section.agreement}
              </p>
              <div className="checkbox">
                <label>
                  <input
                    type="checkbox"
                    name="checkbox"
                    checked={agree}
                    onChange={onChangeAgree}
                  />
                  <span>이 설문에 포함된 개인정보 수집에 동의합니다.</span>
                </label>
              </div>
            </>
          )}
      </div>
      <div className="but_wrap">
        {currentData.hasprev ? (
          <>
            <button
              type="button"
              className="secondary gray large "
              onClick={onClickPrev}
            >
              이전 (Prev)
            </button>
            <button
              type="button"
              className={`primary large ${agree ? "" : "disabled"}`}
              disabled={!agree}
              onClick={onClickNext}
            >
              다음 (Next)
            </button>
          </>
        ) : (
          <button
            type="button"
            className={`primary large full ${agree ? "" : "disabled"}`}
            disabled={!agree}
            onClick={onClickNext}
          >
            시작 (Start)
          </button>
        )}
      </div>
    </div>
  ) : null;
};

export default AnswerSection;
