import React, { useEffect, useState } from "react";

const ChoiceContent = ({
  currentData,
  answer,
  reasons,
  isSelected,
  onToggleAnswer,
  onSelectAnswer,
  onInputReason,
}) => {
  // 로컬 상태로 이유 값을 관리
  const [localReasons, setLocalReasons] = useState(reasons);

  // 부모 컴포넌트에서 reasons가 변경될 때 로컬 상태 업데이트
  useEffect(() => {
    setLocalReasons(reasons);
  }, [reasons]);

  // 이유 입력 처리 핸들러
  const handleReasonChange = (orderno, value) => {
    // 로컬 상태 즉시 업데이트
    setLocalReasons((prev) => ({
      ...prev,
      [orderno]: value,
    }));

    // 부모 컴포넌트의 상태 업데이트 함수 호출
    onInputReason(orderno, value);
  };

  return (
    <ul className="radio checkbox">
      {currentData.question.items.map((item, index) => {
        const shouldShowTextarea = Boolean(
          (item.isetc && isSelected(item)) ||
            (currentData.question.inputreason &&
              item.reasonguide !== null &&
              isSelected(item))
        );
        const placeholderText = item.reasonguide || "답변 입력";
        // 로컬 상태에서 이유 값 가져오기
        const itemReason = localReasons[item.orderno] || "";

        return (
          <li key={`question-${index}`}>
            {currentData.question.multipleselect === 1 ? (
              <label>
                <input
                  type="checkbox"
                  name="checkbox"
                  checked={isSelected(item)}
                  onChange={(e) => {
                    onToggleAnswer(item, e.target.checked);
                  }}
                />
                <span>{item.content}</span>
                {shouldShowTextarea && (
                  <>
                    :
                    <textarea
                      className={`solo etc ${
                        itemReason.length === 300 ? "danger" : ""
                      }`}
                      id={`textarea_auto_${item.orderno}`}
                      placeholder={placeholderText}
                      rows="1"
                      value={itemReason}
                      onChange={(e) =>
                        handleReasonChange(item.orderno, e.target.value)
                      }
                    />
                  </>
                )}
              </label>
            ) : (
              <label>
                <input
                  type="radio"
                  name="question"
                  checked={isSelected(item)}
                  onChange={(e) => {
                    onSelectAnswer(item);
                  }}
                />
                <span>{item.content}</span>
                {shouldShowTextarea && (
                  <>
                    :
                    <textarea
                      className={`solo etc ${
                        itemReason.length === 300 ? "danger" : ""
                      }`}
                      id={`textarea_auto_${item.orderno}`}
                      placeholder={placeholderText}
                      rows="1"
                      value={itemReason}
                      onChange={(e) =>
                        handleReasonChange(item.orderno, e.target.value)
                      }
                    />
                  </>
                )}
              </label>
            )}
          </li>
        );
      })}
    </ul>
  );
};

export default ChoiceContent;
