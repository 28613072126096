import React, {
  createContext,
  useEffect,
  useState,
  useMemo,
  useContext,
  useRef,
} from "react";
import { useApi } from "./ApiContext";
import { useCommonPopup } from "./CommonPopupContext";
import { useProject } from "./ProjectContext";
import { SURVEY_STATUS_WRITING } from "../common/codeHelper";

var SurveyContext = createContext();

export function SurveyContextProvider({ surveyId, children }) {
  const saveTimeout = 200;
  const api = useApi();
  const { alert } = useCommonPopup();
  const project = useProject();
  const [surveyData, setSurveyData] = useState(null);
  const [itemList, setItemList] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [imageDataList, setImageDataList] = useState({});
  const [answerCount, setAnswerCount] = useState(0);
  const [errorData, setErrorData] = useState();
  const [showError, setShowError] = useState();
  const selectedItemRef = useRef();

  useEffect((_) => {
    getSurvey(null, null);
  }, []);

  useEffect(
    (_) => {
      selectedItemRef.current = selectedItem;
    },
    [selectedItem]
  );

  useEffect(
    (_) => {
      if (!!project.projectData && !!surveyData) {
        if (surveyData.status !== SURVEY_STATUS_WRITING) {
          getAnswerCount(project.projectData.isowner === 1 ? "ALL" : "MY");
        }
      }
    },
    [project.projectData, surveyData]
  );

  const getSurvey = async (idsection, idquestion) => {
    const result = await api.request(`/surveys/${surveyId}/`);

    if (result?.status !== 200) {
      api.networkError(result, alert);
      return null;
    } else {
      var survey = result.data;
      var list = [];
      var index = 0;
      var item = null;

      if (idsection == null) idsection = selectedItem?.idsection;
      if (idquestion == null) idquestion = selectedItem?.idquestion;

      survey.idsurvey = surveyId;
      survey.sections.forEach((section, index1) => {
        section["index"] = index1;
        section["listIndex"] = index++;
        list.push(section);
        if (section.idsection === idsection) item = section;
        section.questions.forEach((question, index2) => {
          question["index"] = index2;
          question["listIndex"] = index++;
          question["sectionId"] = section.idsection;
          question["sectionIndex"] = section.orderno;
          question["hasDetail"] = false;
          list.push(question);
          if (question.idquestion === idquestion) item = question;
        });
      });
      if (item == null && selectedItem != null) {
        item =
          list[selectedItem.listIndex] ??
          list[selectedItem.listIndex - 1] ??
          list[0];
      }

      setSurveyData(survey);
      setItemList(list);
      setSelectedItem(item ?? list[0]);

      return survey;
    }
  };

  const getAnswerCount = async (affiliation) => {
    const result = await api.request(`/surveys/${surveyId}/answer_count/`, {
      params: {
        myaffiliation: affiliation === "MY",
      },
    });

    if (result?.status !== 200) {
      api.networkError(result, alert);
    } else {
      setAnswerCount(result.data.answercount);
    }
  };

  const selectItem = (index) => {
    setSelectedItem(itemList[index]);
  };

  const getSection = (sectionId) => {
    if (surveyData == null) return null;

    return itemList?.filter((item) => item.idsection == sectionId)?.[0] ?? null;
  };

  const getQuestion = (questionId) => {
    if (surveyData == null) return null;

    return (
      itemList?.filter((item) => item.idquestion == questionId)?.[0] ?? null
    );
    return null;
  };

  const getItem = (questionId, itemId) => {
    if (surveyData == null) return null;

    const question =
      itemList?.filter((item) => item.idquestion == questionId)?.[0] ?? null;
    if (!!question) {
      return (
        question.items?.filter((item) => item.iditem == itemId)?.[0] ?? null
      );
    }
    return null;
  };

  const updateSurvey = (field, value) => {
    const survey = {
      ...surveyData,
      [field]: value,
    };
    setSurveyData(survey);
  };

  const updateSurveyName = (name) => {
    updateSurvey("name", name);
    if (errorData?.[`survey-${surveyId}`] != null) {
      setErrorData({
        ...errorData,
        [`survey-${surveyId}`]: name.length > 0 ? "" : "name",
      });
    }
  };

  const updateSection = (section) => {
    const old = getSection(section.idsection);
    if (old === section) {
      return;
    }
    const sections = surveyData.sections.map((element) =>
      element.idsection == section.idsection ? section : element
    );
    const survey = {
      ...surveyData,
      sections: sections,
    };
    setSurveyData(survey);
    setItemList(
      itemList.map((element) =>
        element.idsection == section.idsection ? section : element
      )
    );

    if (errorData?.[`section-${section.idsection}`] != null) {
      setErrorData({
        ...errorData,
        [`section-${section.idsection}`]: section.name.length > 0 ? "" : "name",
      });
    }
    if (selectedItemRef.current?.idsection === section.idsection) {
      setSelectedItem(section);
    }
  };

  const updateQuestion = (section, question) => {
    if (section == null || question == null) return;
    const old = getQuestion(question.idquestion);

    if (old === question) return;
    // if (JSON.stringify(old) !== JSON.stringify(question)) return

    const sections = surveyData.sections.map((element) =>
      element.idsection == section.idsection
        ? {
            ...element,
            questions: element.questions.map((element2) =>
              element2.idquestion == question.idquestion ? question : element2
            ),
          }
        : element
    );

    const survey = {
      ...surveyData,
      sections: sections,
    };
    setSurveyData(survey);
    setItemList(
      itemList.map((element) =>
        element.idquestion == question.idquestion ? question : element
      )
    );

    if (!!errorData?.[`question-${question.idquestion}`]) {
      var list = errorData?.[`question-${question.idquestion}`]?.filter(
        (element) => element !== "name"
      );
      var list2 = [];
      question.items?.forEach((element) => {
        if (
          list?.filter((element2) => element2 === `item-${element.iditem}`)
            ?.length > 0
        ) {
          list2.push(`item-${element.iditem}`);
        }
      });
      if (question.content?.length == 0) list2.push("name");

      setErrorData({
        ...errorData,
        [`question-${question.idquestion}`]: list2,
      });
    }
    if (selectedItemRef.current?.idquestion === question.idquestion) {
      setSelectedItem(question);
    }
  };

  const updateItem = (section, question, item) => {
    if (!section || !question || !item) return;

    const old = getItem(question.idquestion, item.iditem);
    if (old === item) return;
    // if (JSON.stringify(old) !== JSON.stringify(item)) return

    const questionData = {
      ...question,
      items: question.items.map((element3) =>
        element3.iditem === item.iditem ? item : element3
      ),
    };
    const sections = surveyData.sections.map((element) =>
      element.idsection == section.idsection
        ? {
            ...element,
            questions: element.questions.map((element2) =>
              element2.idquestion == question.idquestion
                ? questionData
                : element2
            ),
          }
        : element
    );

    const survey = {
      ...surveyData,
      sections: sections,
    };
    setSurveyData(survey);
    setItemList(
      itemList.map((element) =>
        element.idquestion == question.idquestion ? questionData : element
      )
    );

    if (!!errorData?.[`question-${question.idquestion}`]) {
      var list = errorData?.[`question-${question.idquestion}`]?.filter(
        (element) => element !== `item-${item.iditem}`
      );
      if (item.content?.length == 0) list.push(`item-${item.iditem}`);

      setErrorData({
        ...errorData,
        [`question-${question.idquestion}`]: list,
      });
    }
    if (selectedItemRef.current?.idquestion === question.idquestion) {
      setSelectedItem(questionData);
    }
  };

  const setError = async (errors, show) => {
    if (!errors) return;
    var data = {};
    var firstError = null;
    errors.forEach((error, index) => {
      var key = "";
      if (!!error.survey) {
        key = `survey-${error.survey}`;
        data[key] = "name";
      } else if (!!error.section) {
        if (!!error.question) {
          key = `question-${error.question}`;
          if (!data[key]) data[key] = [];
          if (!!error.item) {
            data[key] = [...data[key], `item-${error.item}`];
          } else {
            data[key] = [...data[key], "name"];
          }
        } else {
          key = `section-${error.section}`;
          data[key] = "name";
        }
      }
    });

    if (data[`survey-${surveyId}`] === "name") {
      firstError = {
        type: "survey",
        id: surveyId,
      };
    } else {
      for (let i = 0; i < itemList.length; i++) {
        const item = itemList[i];
        if (!!item.idsection) {
          if (data[`section-${item.idsection}`] === "name") {
            firstError = {
              type: "section",
              id: item.idsection,
            };
            break;
          }
        } else {
          if (data[`question-${item.idquestion}`] != null) {
            firstError = {
              type: "question",
              id: item.idquestion,
            };
            break;
          }
        }
      }
    }

    if (show === true) {
      setShowError(firstError);
    } else {
      setShowError(null);
    }
    setErrorData(data);
  };

  const hasError = (key, subKey) => {
    if (!errorData) return false;

    if (key.startsWith("question")) {
      if (!!subKey) {
        return errorData[key]?.filter((item) => item === subKey)?.length > 0;
      } else {
        return errorData[key]?.length > 0;
      }
    }
    return errorData[key] === "name";
  };

  const checkError = async () => {
    const result = await api.request(`/surveys/${surveyId}/check/`, {
      method: "post",
    });

    if (result?.status !== 200) {
      // await api.networkError(result, alert)
    } else {
      setError(result?.data?.errors, false);
    }
  };

  const hasSectionError = (section) => {
    return section.name.length === 0;
  };

  const hasQuestionError = (question) => {
    if (question.content.length === 0) return true;
    for (let i = 0; i < question.items?.length; i++) {
      const item = question.items[i];
      if (item.content?.length === 0) return true;
    }
    return false;
  };

  const hasItemError = (item) => {
    return item.content.length === 0;
  };

  const downloadImage = async (idquestion, imageId) => {
    if (imageId?.length === 0) {
      setImageDataList({
        ...imageDataList,
        [idquestion]: null,
      });
      return;
    }

    const imageData = imageDataList[idquestion];
    if (imageData != null && imageData.imageId === imageId) {
      return;
    }

    setImageDataList({
      ...imageDataList,
      [idquestion]: {
        imageId: imageId,
        data: null,
      },
    });

    const result = await api.post("downloadQuestionImage", {
      question: idquestion,
    });

    if (result?.data?.result != "ok") {
      setImageDataList({
        ...imageDataList,
        [idquestion]: {
          imageId: imageId,
          status: "error",
          data: null,
        },
      });
    } else {
      setImageDataList({
        ...imageDataList,
        [idquestion]: {
          imageId: imageId,
          status: "loaded",
          data: result.data.data.image,
        },
      });
    }
  };

  const getImageData = (idquestion) => {
    const imageData = imageDataList[idquestion];
    return imageData?.data;
  };

  return (
    <SurveyContext.Provider
      value={{
        surveyId,
        surveyData,
        answerCount,
        // selectIndex, setSelectIndex,
        selectedItem,
        selectItem,
        itemList,
        getSurvey,
        getSection,
        getQuestion,
        getItem,
        updateSurvey,
        updateSurveyName,
        updateSection,
        updateQuestion,
        updateItem,
        imageDataList,
        downloadImage,
        getImageData,
        errorData,
        setError,
        showError,
        hasError,
        checkError,
        hasSectionError,
        hasQuestionError,
        hasItemError,
        saveTimeout,
      }}
    >
      {children}
    </SurveyContext.Provider>
  );
}

export function useSurvey() {
  return useContext(SurveyContext);
}
